import { Campaign, CampaignType } from '@book-nestor-monorepo/shared-types';
import { faChevronRight, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateString } from '../../libs/utils/date.util';
import { PaginationMeta } from '@book-nestor-monorepo/nestor-dtos';

const translateCampaignType = (type: CampaignType) => {
  switch (type) {
    case CampaignType.FILL_SLOT:
      return 'Fill Slot';
    case CampaignType.GENERAL:
      return 'General';
    default:
      return type;
  }
};

export const CampaignTable: React.FC<{
  campaigns: Campaign[];
  campaignsMeta: PaginationMeta;
}> = ({ campaigns, campaignsMeta }) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const navigate = useNavigate();

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="flex-col lg:bg-white rounded-lg shadow shadow-gray-200 p-2 lg:p-4">
      <div className="flex w-full items-center justify-between mb-4">
        <h2 className="text-[22px] font-medium font-avenir ">Campaigns</h2>

        <button
          className=" w-auto bg-black text-white rounded-md text-[14px] font-avenir font-normal px-4 py-3 hidden lg:block"
          onClick={() => {
            navigate('/campaigns/general');
          }}
        >
          Create a new Campaign
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
        <button
          className=" w-auto bg-black text-white rounded-md text-[12px] font-avenir font-normal px-4 py-2 block lg:hidden"
          onClick={() => {
            navigate('/campaigns/general');
          }}
        >
          Create
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
      </div>

      <div className="w-full h-full lg:bg-[#F5F5F7] lg:rounded-lg">
        {/* Mobile view (up to lg breakpoint) */}
        <div className="lg:hidden space-y-2">
          {campaigns.map((campaign, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`p-4 bg-white ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <div className="w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex font-bold font-avenir text-[12px]">{campaign.name}</div>
                    <div className="flex font-avenir text-[12px]">
                      {translateCampaignType(campaign.type)}
                    </div>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex font-avenir text-[12px]">
                      {formatDateString(campaign.created_at)}
                    </div>
                  </div>
                  <div className="flex w-full items-end justify-between mt-6">
                    <div className="flex-col items-start justify-start">
                      {campaign.recipients.length > 0 && (
                        <span className="flex bg-black text-white px-3 py-2 rounded-full text-[10px] w-auto">
                          {campaign.recipients[0]?.contact?.name}
                          {campaign.recipients.length > 1 && `, +${campaign.recipients.length - 1}`}
                        </span>
                      )}
                      {campaign.include_all_contacts && (
                        <span className="flex bg-black text-white px-3 py-2 rounded-full text-[10px] w-auto">
                          All Contacts
                        </span>
                      )}
                    </div>
                    <div
                      className="flex h-full items-end justify-end"
                      onClick={() => toggleRow(index)}
                    >
                      <div className="h-10 w-10 rounded-full bg-[#BCC8C7] flex items-center justify-center cursor-pointer">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className={`text-[12px] transform transition-transform duration-200 ${
                            expandedRows[index] ? 'rotate-90' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {expandedRows[index] && (
                <div className="bg-white mb-2 p-4 rounded-b-xl">
                  <div className="space-y-2 font-avenir">
                    <div className="flex justify-between items-center">
                      <div className="text-[12px] text-gray-600">Delivery Status</div>
                      <div className="font-medium capitalize text-[12px]">
                        {campaign.campaign_delivery_status}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-[12px] text-gray-600">Failed Messages</div>
                      <div className="font-medium text-[12px]">
                        {campaign.campaign_metrics?.failed_message_count || 0}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-[12px] text-gray-600">Sent Messages</div>
                      <div className="font-medium text-[12px]">
                        {campaign.campaign_metrics?.sent_message_count || 0}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-[12px] text-gray-600">Response Rate</div>
                      <div className="font-medium text-[12px]">
                        {Number(campaign.campaign_metrics?.response_rate || 0) * 100 || 0}%
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Desktop view (lg and above) */}
        <div className="hidden lg:block">
          <div className="min-w-full w-full p-2">
            <div className="flex text-[14px] font-inter text-gray-600">
              <div className="flex-1 p-4">Name</div>
              <div className="flex-1 p-4">Created At</div>
              <div className="flex-1 p-4">Type</div>
              <div className="flex-1 p-4">Delivery Method</div>
              <div className="flex-1 p-4">Recipients</div>
              <div className="flex-1 p-0"></div>
            </div>
            <div className="space-y-2 mt-2">
              {campaigns.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <div
                    className={`flex ${
                      index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'
                    } ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}  cursor-pointer`}
                  >
                    <div className="flex-1 p-4 font-bold font-avenir text-[12px]">{item.name}</div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {formatDateString(item.created_at)}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {translateCampaignType(item.type)}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">{item.delivery_method}</div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {item.recipients.length > 0 && (
                        <span className="bg-black text-white px-3 py-2 rounded-full text-[10px]">
                          {`${item.recipients[0]?.contact?.name}`}
                          <span className="font-bold ml-1">
                            {item.recipients.length > 1 && `+${item.recipients.length - 1}`}
                          </span>
                        </span>
                      )}
                      {item.include_all_contacts && (
                        <span className="bg-black text-white px-3 py-2 rounded-full text-[10px]">
                          All Contacts
                        </span>
                      )}
                    </div>
                    <div
                      className="flex-1 py-2 pt-3 cursor-pointer"
                      onClick={() => toggleRow(index)}
                    >
                      <span className="text-[12px] font-bold">Details</span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className={`text-[12px] ml-2 transform transition-transform duration-200 ${
                          expandedRows[index] ? 'rotate-90' : ''
                        }`}
                      />
                    </div>
                  </div>
                  {expandedRows[index] && (
                    <div
                      className={` mb-2 p-4 rounded-b-xl ${index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'}`}
                    >
                      <div className="space-y-2 font-avenir">
                        <div className="flex justify-between items-center">
                          <div className="text-[12px] text-gray-600">Delivery Status</div>
                          <div className="font-medium capitalize text-[12px]">
                            {item.campaign_delivery_status}
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="text-[12px] text-gray-600">Failed Messages</div>
                          <div className="font-medium text-[12px]">
                            {item.campaign_metrics?.failed_message_count || 0}
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="text-[12px] text-gray-600">Sent Messages</div>
                          <div className="font-medium text-[12px]">
                            {item.campaign_metrics?.sent_message_count || 0}
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="text-[12px] text-gray-600">Response Rate</div>
                          <div className="font-medium text-[12px]">
                            {Number(item.campaign_metrics?.response_rate || 0) * 100 || 0}%
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {campaignsMeta.hasMore && (
        <div className="flex justify-between items-center">
          <div className="flex-1 pt-2 items-center justify-center">
            <button className="text-[12px] font-avenir font-medium">
              Next <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
