import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';

import { User, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { createUser, getUserById } from '../libs/services/user';
import { getServicePhoneDetailsByUser } from '../libs/services/voice';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { environment } from '../environments/environment';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

interface IAuth {
  authStatus?: AuthStatus;
  username?: string;
  firstName?: string;
  user?: User;
  token?: any;
  servicePhone?: UserServicePhone;

  updateSession?: () => void;
  updateLoggedInUser?: (user: User) => void;
  updateUserServicePhone?: (servicePhone: UserServicePhone) => void;
  handleLoginFlow?: () => void;
  bookingLink?: () => string;
}

const defaultState: IAuth = {
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [user, setUser] = useState<User | undefined>(undefined);
  const [token, setToken] = useState<any>(undefined);
  const [servicePhone, setServicePhone] = useState<UserServicePhone | undefined>(undefined);
  const navigate = useNavigate();

  const listener = (data: any) => {
    switch (data.payload.event) {
      case 'login-username-set':
        setUsername(data?.payload?.data?.loginUserName); // need this for verify screen
        break;
      case 'login-first-name-set':
        setFirstName(data?.payload?.data?.firstName); // need this for verify screen
        break;
      case 'login-username-remove':
        setUsername(''); // need this for verify screen
        break;
      case 'configured':
        break;
      case 'signedIn':
        setAuthStatus(AuthStatus.SignedIn);
        navigate('/');
        break;
      case 'signIn_failure':
        break;
      case 'signUp':
        setUsername(data?.payload?.data?.user?.username); // need this for verify screen
        break;
      case 'signUp_failure':
        break;
      case 'confirmSignUp':
        break;
      case 'completeNewPassword_failure':
        break;
      case 'autoSignIn':
        setAuthStatus(AuthStatus.SignedIn);
        break;
      case 'autoSignIn_failure':
        break;
      case 'forgotPassword':
        break;
      case 'forgotPassword_failure':
        break;
      case 'forgotPasswordSubmit':
        break;
      case 'forgotPasswordSubmit_failure':
        break;
      case 'verify':
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        break;
      case 'cognitoHostedUI':
        break;
      case 'cognitoHostedUI_failure':
        break;
      case 'customOAuthState':
        break;
      case 'customState_failure':
        break;
      case 'parsingCallbackUrl':
        break;
      case 'userDeleted':
        setAuthStatus(AuthStatus.SignedOut);
        break;
      case 'updateUserAttributes':
        break;
      case 'updateUserAttributes_failure':
        break;
      case 'signedOut':
        logoutUser();
        break;
    }
  };

  Hub.listen('auth', listener);

  const mainUseEffectRef = useRef(false);

  useEffect(() => {
    // PREVENT from double run
    // if the useEffect is called again, we don't want to run the code again.
    if (mainUseEffectRef.current) return;

    mainUseEffectRef.current = true;

    async function getSessionInfo() {
      try {
        setAuthStatus(AuthStatus.Loading);
        const session = await fetchAuthSession();

        const idToken = session.tokens?.idToken?.payload;
        if (idToken && !user) {
          const createdUser = await createUser(
            idToken?.sub || '',
            idToken?.email as string,
            idToken?.name as string,
            idToken?.phone_number as string,
            idToken?.picture as string,
          );
          setUser(createdUser);
          setToken(idToken);
          setAuthStatus(AuthStatus.SignedIn);

          try {
            const servicePhone = await getServicePhoneDetailsByUser(createdUser.id || '');
            setServicePhone(servicePhone);
          } catch (err) {
            Sentry.captureException(err);
          }
        } else {
          setAuthStatus(AuthStatus.SignedOut);
        }
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();

    // return () => {
    //   mainUseEffectRef.current = true;
    // };
  }, []);

  // this method is called by onboardingContext, it loads the user into the app for the first time after login.
  // the effect above this handles already logged in users but does not handle login flow.
  const handleLoginFlow = async () => {
    try {
      setAuthStatus(AuthStatus.Loading);
      const session = await fetchAuthSession();

      const idToken = session.tokens?.idToken?.payload;
      if (idToken && !user) {
        let user: User | undefined;
        // RACE CONDITION - result in 401
        // try {
        //   user = await getUserById(idToken?.sub || ''); // 401 ???
        // } catch (err) {
        //   Sentry.captureException(err);
        // }
        if (!user) {
          user = await createUser(
            idToken?.sub || '',
            idToken?.email as string,
            idToken?.name as string,
            idToken?.phone_number as string,
            idToken?.picture as string,
            idToken?.['cognito:username'] as string,
          );
        }

        setUser(user);
        setToken(idToken);
        setAuthStatus(AuthStatus.SignedIn);

        try {
          const servicePhone = await getServicePhoneDetailsByUser(user.id || '');
          setServicePhone(servicePhone);
        } catch (err) {
          Sentry.captureException(err);
        }
        return user;
      } else {
        setAuthStatus(AuthStatus.SignedOut);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const updateLoggedInUser = (user: User) => {
    setUser(user);
  };

  const updateUserServicePhone = (servicePhone: UserServicePhone) => {
    setServicePhone(servicePhone);
  };

  const bookingLink = () => {
    if (user?.user_slug?.slug) {
      return `${environment.baseBookingUrl}/${user.user_slug.slug}`;
    }
    return `${environment.baseBookingUrl}`;
  };

  const logoutUser = () => {
    setUser(undefined);
    setToken(undefined);
    setAuthStatus(AuthStatus.SignedOut);
    setServicePhone(undefined);
    setUsername('');
    setFirstName('');
  };

  const updateSession = async () => {
    const session = await fetchAuthSession({ forceRefresh: true });

    if (user?.id) {
      const fetchedUser = await getUserById(user.id);
      setUser(fetchedUser);
    }

    const idToken = session.tokens?.idToken?.payload;
    setToken(idToken);
  };

  const auth: IAuth = useMemo(
    () => ({
      authStatus,
      username,
      firstName,
      user,
      token,
      servicePhone,
      updateLoggedInUser,
      updateSession,
      handleLoginFlow,
      bookingLink,
      updateUserServicePhone,
    }),
    [authStatus, username, firstName, user, token, servicePhone],
  );

  // if (authStatus === AuthStatus.Loading) {
  //   return <LoadingDots />; // Render a loading state while fetching the user
  // }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
