import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { CalendarError } from './calendar-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CalendarErrorView({ error }: { error: CalendarError }) {
  return (
    <div className="bg-gray-50 lg:h-0 lg:min-h-[768px] font-inter rounded-2xl">
      <div className="lg:flex lg:h-full lg:flex-col rounded-2xl items-center justify-center">
        <div className="flex flex-col items-center justify-center p-4">
          <p className="text-black font-normal font-avenir text-center">
            Oops, something went wrong.
          </p>
          <button
            className="mt-2 px-4 py-2 bg-black text-white rounded-2xl flex items-center justify-center font-avenir font-normal text-[12px]"
            onClick={error.retry}
          >
            <FontAwesomeIcon icon={faRefresh} className="text-white mr-2" /> Try again
          </button>
        </div>
      </div>
    </div>
  );
}
