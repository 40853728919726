import {
  EventType,
  Organization,
  Review,
  ReviewSummary,
  Schedule,
  User,
  SubscriptionPlan,
  USER_PERMISSIONS,
  USER_PERMISSIONS_MAP,
} from '@book-nestor-monorepo/shared-types';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import BookingDetails from '../components/booking/BookingDetails';
import BookingDetailsMobile from '../components/booking/BookingDetailsMobile';
import BookingSliderMobile from '../components/booking/BookingSliderMobile';
import BookingTabs from '../components/booking/BookingTabs';
import BookingTabsMobile from '../components/booking/BookingTabsMobile';
import { environment } from '../environments/environment';
import { getDefaultSchedule } from '../libs/services/bookings';
import { getEventTypes } from '../libs/services/event-type';
import { incrementSiteVisit } from '../libs/services/insights';
import { getOrganization } from '../libs/services/organization.service';
import { getReviewSummary } from '../libs/services/reviews';
import { getUserBySlug } from '../libs/services/user';
import { BookingLayout } from './layouts/booking-layout';
import * as Sentry from '@sentry/react';

const tabsArray = [
  {
    label: 'Services',
    active: true,
  },
  {
    label: 'Details',
    active: false,
  },
  {
    label: 'Hours',
    active: false,
  },
  {
    label: 'Reviews',
    active: false,
  },
];

export default function BookingNew() {
  const { userSlug } = useParams<{ userSlug: string }>();
  const [searchParams] = useSearchParams();
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);
  const [tabs, setTabs] = useState(tabsArray);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isUserActive, setIsUserActive] = useState(false);
  const [reviewsSummary, setReviewsSummary] = useState<ReviewSummary | undefined>(undefined);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [defaultSchedule, setDefaultSchedule] = useState<Schedule | undefined>(undefined);
  const contactId = searchParams.get('name') || undefined;

  const [isLoading, setIsLoading] = useState(true);
  const selectTab = (idx: number) => {
    const newTabs = [...tabs];

    for (let i = 0; i < newTabs.length; i++) {
      if (i === idx) {
        newTabs[i].active = true;
      } else {
        newTabs[i].active = false;
      }
    }

    setTabs(newTabs);
  };

  const userCanUseBusinessReviews = (_user: User): boolean => {
    const userSubscription = _user?.subscription;
    const userPermissions =
      USER_PERMISSIONS_MAP[userSubscription as SubscriptionPlan]?.permissions || [];

    return userPermissions.includes(USER_PERMISSIONS.CAN_USE_BUSINESS_REVIEWS);
  };

  const userUploadPhotoLimit = (_user: User) => {
    switch (_user?.subscription) {
      case SubscriptionPlan.PRO:
        return 8;
      case SubscriptionPlan.STUDIO:
        return 12;
      default:
        return 4;
    }
  };

  const userCanUseBusinessDescription = (_user: User): boolean => {
    const userSubscription = _user?.subscription;
    const userPermissions =
      USER_PERMISSIONS_MAP[userSubscription as SubscriptionPlan]?.permissions || [];

    return userPermissions.includes(USER_PERMISSIONS.CAN_USE_BUSINESS_DESCRIPTION);
  };

  const filterTabs = (_user: User) => {
    let tabsToShow = tabsArray;
    if (!userCanUseBusinessReviews(_user)) {
      // remove the Reviews tab
      tabsToShow = tabsToShow.filter((tab) => tab.label !== 'Reviews');
    }
    setTabs(tabsToShow);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const _user = await getUserBySlug(userSlug as string);
        if (!_user) {
          return;
        }

        const [reviewsSummaryResult, eventTypesResult, defaultScheduleResult] =
          await Promise.allSettled([
            getReviewSummary(_user?.id as string),
            getEventTypes(_user?.id as string),
            getDefaultSchedule(_user?.id as string),
          ]);

        const reviewsSummary =
          reviewsSummaryResult.status === 'fulfilled' && userCanUseBusinessReviews(_user)
            ? reviewsSummaryResult.value
            : undefined;
        const eventTypes = eventTypesResult.status === 'fulfilled' ? eventTypesResult.value : [];
        const defaultSchedule =
          defaultScheduleResult.status === 'fulfilled' ? defaultScheduleResult.value : undefined;

        setUser(_user);
        setIsUserActive(_user?.subscription === SubscriptionPlan.INACTIVE ? false : true);

        const organization = await getOrganization(_user.id);
        setOrganization(organization);

        setReviewsSummary(reviewsSummary);
        setEventTypes(eventTypes);
        setDefaultSchedule(defaultSchedule);
        filterTabs(_user);

        try {
          await incrementSiteVisit(
            `${environment.baseBookingUrl}/${userSlug}`,
            _user?.id as string,
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <BookingLayout
      isLoading={isLoading}
      hideBranding={organization?.settings?.booking_page?.hide_branding}
    >
      {!isUserActive ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">This user is no longer active</h1>
          </div>
        </div>
      ) : (
        <>
          <div className="md:w-[750px] lg:w-[1000px] mx-auto md:pb-[24px]">
            <BookingDetailsMobile
              organization={organization as Organization}
              reviewsSummary={reviewsSummary as ReviewSummary}
            />
            <BookingDetails
              organization={organization as Organization}
              reviewsSummary={reviewsSummary as ReviewSummary}
              userCanUseBusinessReviews={userCanUseBusinessReviews(user as User)}
              userCanUseBusinessDescription={userCanUseBusinessDescription(user as User)}
              userUploadPhotoLimit={userUploadPhotoLimit(user as User)}
            />
          </div>
          <BookingTabs
            tabs={tabs}
            selectTab={selectTab}
            eventTypes={eventTypes}
            organization={organization as Organization}
            defaultSchedule={defaultSchedule as Schedule}
            reviews={reviewsSummary?.top_reviews as Review[]}
            contactId={contactId}
          />
          <BookingTabsMobile
            tabs={tabs}
            selectTab={selectTab}
            eventTypes={eventTypes}
            organization={organization as Organization}
            defaultSchedule={defaultSchedule as Schedule}
            reviews={reviewsSummary?.top_reviews as Review[]}
            contactId={contactId}
          />
          <BookingSliderMobile
            organization={organization as Organization}
            userUploadPhotoLimit={userUploadPhotoLimit(user as User)}
          />
        </>
      )}
    </BookingLayout>
  );
}
