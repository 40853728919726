import {
  CampaignDeliveryMethod,
  CampaignType,
  Contact,
  ContactRecommendation,
  CreateCampaign,
  DescriptionsRecommendation,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { faArrowDown, faArrowLeft, faInfoCircle, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import RecipientChips from '../../../components/app/v2/chipList';
import EditableMessageChips from '../../../components/app/v2/editableChipList';
import PrimaryTextField from '../../../components/formLibrary/primaryTextInput';
import LoadingDots from '../../../components/loading';
import { AgentAiBorder } from '../../../components/v2/Agent/AgentAiBorder';
import { AuthContext } from '../../../contexts/authContext';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { createCampaign, improveCampaignDescription } from '../../../libs/services/campaign';
import { formatIsoDateString } from '../../../libs/utils/date.util';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { CampaignBrochure } from '../campaign-brochure';
import { CampaignBuilderContactModal } from '../campaign-builder-contact-modal';

export const CampaignBuilderGeneralV2 = () => {
  const { bgColor } = useAppExperience();
  const navigate = useNavigate();
  //   const { bookingId } = useParams();
  const { user, token } = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const [isGeneratingDescriptions, setIsGeneratingDescriptions] = useState(false);
  const [messages, setMessages] = useState<DescriptionsRecommendation[]>([]);
  const [recipients, setRecipients] = useState<Partial<ContactRecommendation>[]>([]);
  const [includeAllContacts, setIncludeAllContacts] = useState(false);
  const [editingText, setEditingText] = useState('');

  const userCanUseCampaigns = hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT);

  const [initialValues, setInitialValues] = useState<CreateCampaign>({
    name: '',
    type: CampaignType.GENERAL,
    delivery_method: CampaignDeliveryMethod.SMS,
    recipients: [],
    message: '',
    booking_id: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleModalOpen = () => {
    openMuiModal(
      <CampaignBuilderContactModal
        onClose={closeMuiModal}
        onSelectContact={(contact: Contact | null) => {
          console.log(contact);
          if (contact) {
            setRecipients([...recipients, { contact }]);
            setIncludeAllContacts(false);
          }
        }}
        onSelectAllContacts={() => {
          setIncludeAllContacts(true);
        }}
        includeAddAllContacts={true}
      />,
      3,
    );
  };

  const handleImproveDescription = async () => {
    setIsGeneratingDescriptions(true);
    const descriptions = await improveCampaignDescription(user?.id as string, editingText);
    setMessages(descriptions || []);
    setIsGeneratingDescriptions(false);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setInitialValues({
        name: `General - ${formatIsoDateString(new Date().toISOString())}`,
        type: CampaignType.GENERAL,
        delivery_method: CampaignDeliveryMethod.SMS,
        recipients: [],
        message: '',
        booking_id: '',
        include_all_contacts: false,
      });
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      type: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const submitCampaign = async () => {
    const mappedValues = mapValuesToCreateCampaign(formik.values);
    const campaign = await createCampaign(mappedValues);
    console.log(campaign);
    navigate('/campaigns');
  };

  const mapValuesToCreateCampaign = (values: any): CreateCampaign => {
    return {
      name: values.name,
      type: values.type,
      delivery_method: values.delivery_method,
      recipients: recipients,
      message: editingText,
      booking_id: values.booking_id,
      include_all_contacts: includeAllContacts,
    };
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditingText(e.target.value);
  };

  const createContactFormIsValid =
    (recipients.length > 0 || includeAllContacts) &&
    editingText &&
    formik.values.name &&
    formik.values.delivery_method;

  const contactListIsFull = recipients.length >= 10;

  const handleDeleteRecipient = (index: number) => {
    const newRecipients = [...recipients];
    newRecipients.splice(index, 1);
    setRecipients(newRecipients);
  };

  if (!userCanUseCampaigns) {
    return (
      <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
        <div className="flex flex-col h-auto w-full overflow-hidden">
          <CampaignBrochure />
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        <div className="flex justify-between items-center w-full pt-4 pb-4 lg:pb-8">
          <div className="flex items-center justify-between w-full px-1">
            <div className="flex items-center">
              <div className="hidden lg:block">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="cursor-pointer text-black text-[16px] pb-[2px] pr-4 font-avenir font-normal pt-[2px]"
                />
              </div>
              <span
                className="hidden lg:block text-black text-base cursor-pointer"
                onClick={() => {
                  navigate('/campaigns');
                }}
              >
                {'Campaigns > '}
              </span>
              <span className="text-black text-base ml-2 cursor-auto font-bold">
                {formik.values.name}
              </span>
            </div>
            <button
              className={`h-10 px-4 bg-black text-white rounded-md  text-[12px] font-avenir font-normal ${
                createContactFormIsValid ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={!createContactFormIsValid}
              onClick={submitCampaign}
            >
              Submit
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="z-1 my-8 sm:my-0 lg:flex">
            <div className="pl-2 pr-3 lg:sticky lg:top-6 lg:h-0 lg:pl-0">
              <div className="mb-5 lg:w-[250px]">
                <PrimaryTextField
                  label="Campaign Name"
                  fullWidth={true}
                  placeholder="Enter campaign name"
                  variant="filled"
                  required={true}
                  {...formik.getFieldProps('name')}
                />
                <PrimaryTextField
                  label="Delivery Method"
                  fullWidth={true}
                  placeholder="Enter campaign name"
                  variant="filled"
                  disabled={true}
                  required={true}
                  {...formik.getFieldProps('delivery_method')}
                  sx={{
                    mt: 2,
                  }}
                />
                <div className="w-full flex items-start">
                  <button
                    className="mt-4 h-10 w-auto bg-black text-white rounded-md text-[12px] font-avenir font-normal px-4"
                    onClick={() => {
                      navigate('/campaigns');
                    }}
                  >
                    Cancel Campaign
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-muted  border-[#D1D5DB] w-full rounded-md border p-3 py-5 lg:ml-3 lg:p-8 h-auto">
              <div>
                <div className="flex justify-center">
                  <div className="min-w-80 bg-default border-[#D1D5DB] w-full rounded-md border p-4 lg:p-7 bg-[#F9F9F9]">
                    <div className="flex">
                      <div className="bg-[#D1D5DB] text-default mt-[3px] flex h-5 w-5 items-center justify-center rounded-full p-1 text-xs font-medium mr-5 ml-1 lg:ml-5 font-avenir">
                        1
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="text-emphasis text-base font-avenir font-bold leading-5">
                          Recipients
                        </div>
                        <div className="text-default text-sm font-avenir">
                          Who recieves the campaign SMS
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between lg:justify-end items-center pt-7">
                      <button
                        className={`h-10 w-auto bg-black text-white rounded-md text-[12px] font-avenir font-normal px-4 ${
                          contactListIsFull ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handleModalOpen}
                        disabled={contactListIsFull}
                      >
                        Add Recipients{' '}
                        <FontAwesomeIcon icon={faPlus} className="text-[12px] ml-1 " />
                      </button>
                    </div>
                    <div className="border-[#D1D5DB] my-7 border-t"></div>
                    <div id="recipients-list">
                      <RecipientChips
                        recipients={recipients}
                        onDelete={(index: number) => {
                          handleDeleteRecipient(index);
                        }}
                      />
                    </div>
                    {includeAllContacts && (
                      <RecipientChips
                        recipients={[{ contact: { name: 'All', last_name: 'Contacts' } }]}
                        onDelete={(index: number) => {
                          setIncludeAllContacts(false);
                        }}
                      />
                    )}
                    {recipients.length === 0 && !includeAllContacts && (
                      <div className="flex items-center justify-center mt-4 mb-8">
                        <span className="text-subtle text-[14px] font-avenir">
                          Add recipients to start your campaign
                        </span>
                      </div>
                    )}
                    {includeAllContacts && (
                      <div className="mt-4 flex text-gray-500 items-start">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="text-[12px] text-[#727272] mt-[2px]"
                        />
                        <span className="text-[12px] ml-1 text-[#727272] font-avenir text-left">
                          This campaign message will be sent to all contacts in your Contacts list.
                          Ensure your message is relevant and tailored to your audience.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-3 flex justify-center">
                <FontAwesomeIcon icon={faArrowDown} className="text-subtle text-[10px]" />
              </div>
              <div className="flex justify-center">
                <div className="min-w-80 bg-default border-[#D1D5DB] w-full rounded-md border p-4 lg:p-7 bg-[#F9F9F9]">
                  <div className="flex">
                    <div className="bg-[#D1D5DB] text-default mt-[3px] flex h-5 w-5 items-center justify-center rounded-full p-1 text-xs font-medium mr-5 ml-1 lg:ml-5 font-avenir">
                      2
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="text-emphasis text-base font-avenir font-bold leading-5">
                        Message
                      </div>
                      <div className="text-default text-sm font-avenir">
                        What the campaign SMS says
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between lg:justify-end items-center pt-7">
                    <AgentAiBorder
                      styles={{
                        rounded: 'md',
                        disabled: isGeneratingDescriptions || !editingText,
                      }}
                    >
                      <button
                        className={`h-10 w-auto bg-black text-white rounded-md text-[12px] font-avenir font-normal px-4 ${
                          isGeneratingDescriptions || !editingText
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        onClick={handleImproveDescription}
                        disabled={isGeneratingDescriptions || !editingText}
                      >
                        Improve <FontAwesomeIcon icon={faSparkles} className="text-[12px] ml-1 " />
                      </button>
                    </AgentAiBorder>
                  </div>
                  <div className="border-[#D1D5DB] my-7 border-t"></div>

                  {messages.length === 0 && !isGeneratingDescriptions && (
                    <div className="flex items-center justify-center mt-10 mb-4">
                      <span className="text-subtle text-[14px] font-avenir">
                        Enter a message to send to your recipients, personalize with{' '}
                        {`{contact_name}`}
                      </span>
                    </div>
                  )}

                  {isGeneratingDescriptions ? (
                    <div className="flex-col items-center justify-between mt-4 mb-8">
                      <span className="text-subtle text-[10px] font-avenir">
                        Generating messages can take up to 1 minute
                      </span>
                      <LoadingDots
                        skeletonProps={{
                          count: 2,
                        }}
                      />
                    </div>
                  ) : (
                    <div id="recipients-list">
                      <EditableMessageChips
                        messages={messages}
                        onDelete={() => {
                          console.log('delete');
                        }}
                        onSelect={(message) => {
                          setEditingText(message.description);
                        }}
                      />
                    </div>
                  )}
                  <div className="flex flex-col space-y-3 mt-8">
                    <textarea
                      value={editingText}
                      onChange={handleTextChange}
                      className="w-full p-3 border border-gray-300 rounded-md font-avenir text-sm min-h-[100px]  outline-none"
                      placeholder="Hey, {contact_name}! Just wanted to let you know..."
                    />
                  </div>
                  <div className="mt-4 flex text-gray-500 items-start">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-[12px] text-[#727272] mt-[2px]"
                    />
                    <span className="text-[12px] ml-1 text-[#727272] font-avenir text-left">
                      Your AI agent can give you suggestions to improve the contents and tone of
                      your message.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
