import React from 'react';
import { CalendarDay } from './calendar-container';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { cleanCalcomEventTitleToJustEventType, truncateText } from '../../libs/utils/string.util';

const CalendarWeekEventsView = ({
  days,
  onEventClick,
  selectedDay,
}: {
  days: CalendarDay[];
  onEventClick: (bookingId: string) => void;
  selectedDay: CalendarDay | null;
}) => {
  const { isMobile, isTablet } = useAppExperience();
  // Helper function to calculate grid row based on ISO datetime
  const calculateGridRow = (datetime: string) => {
    const date = new Date(datetime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutes = hours * 60 + minutes;
    // Each row represents 5 minutes (12 rows per hour * 24 hours = 288 rows)
    return Math.floor(totalMinutes / 5 + 1);
  };

  // Helper function to calculate event duration in grid spans
  const calculateSpan = (startDatetime: string, endDatetime: string) => {
    if (!endDatetime) return 12; // Default 1-hour span if no end time

    const start = new Date(startDatetime);
    const end = new Date(endDatetime);

    const startMinutes = start.getHours() * 60 + start.getMinutes();
    const endMinutes = end.getHours() * 60 + end.getMinutes();

    // Add 1 to the span calculation to eliminate gaps
    return Math.floor((endMinutes - startMinutes) / 5) + 1;
  };

  // Get first 7 days
  const firstWeek = isMobile ? [selectedDay || days[0]] : days.slice(0, 7);
  console.log(firstWeek);

  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
      style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
    >
      {firstWeek.map((day, dayIndex) =>
        (day.events || []).map((event, eventIndex) => {
          if (event.is_cancelled) return null;

          const gridRowStart = calculateGridRow(event.datetime);
          const span = calculateSpan(event.datetime, event.end_time);
          const isShortAppointment = span <= 6; // 30 minutes or less
          const isVeryShortAppointment = span <= 3; // 15 minutes or less
          const isNormalAppointment = span > 6;

          return (
            <li
              key={event.id || `${dayIndex}-${eventIndex}`}
              className="relative flex cursor-pointer mt-[6px]"
              style={{
                gridRow: `${gridRowStart} / span ${span}`,
                gridColumn: dayIndex + 1,
              }}
              onClick={() => onEventClick(event.bookingId)}
            >
              <span
                className={`group absolute inset-1 flex overflow-y-auto rounded-lg bg-gray-100 hover:bg-gray-200 hide-scrollbar ${
                  isVeryShortAppointment ? 'py-0 px-2' : 'p-2'
                } ${isVeryShortAppointment ? 'text-[8px]/4' : 'text-xs/5'} ${
                  isShortAppointment ? 'flex-row items-center justify-start' : 'flex-col'
                } ${event.is_cancelled ? 'opacity-75' : ''}`}
              >
                <div
                  className={`${isShortAppointment ? 'flex flex-row justify-start w-full gap-2' : 'flex flex-col justify-start items-start w-full'}`}
                >
                  <p
                    className={`font-semibold text-gray-700 ${isVeryShortAppointment ? 'text-[10px]' : ''} `}
                  >
                    {truncateText(
                      cleanCalcomEventTitleToJustEventType(event.name || ''),
                      isMobile ? 30 : isTablet ? 14 : 23,
                    )}
                    <span className="inline-flex items-center gap-1">
                      {isMobile && <>- {event.contact_name}</>}
                      {isMobile && event.is_paid && (
                        <span
                          className={`inline-block w-[6px] h-[6px] rounded-full bg-[#43CA51] ml-1`}
                        />
                      )}
                    </span>
                  </p>
                  {isMobile || isNormalAppointment ? (
                    <>
                      {isNormalAppointment && !isTablet && (
                        <>{truncateText(event.contact_name, 20)} </>
                      )}
                      <p
                        className={`text-gray-500 group-hover:text-gray-700 ${isVeryShortAppointment ? 'text-[10px]' : ''} `}
                      >
                        {isTablet && (
                          <div className="flex flex-col items-start">
                            {truncateText(event.contact_name, 20)}
                            <time dateTime={event.datetime}>{event.time}</time>
                          </div>
                        )}
                        {isNormalAppointment && !isTablet && (
                          <time dateTime={event.datetime}>
                            {event.time} - {event.formatted_end_time}
                          </time>
                        )}
                      </p>
                    </>
                  ) : null}
                </div>
              </span>
            </li>
          );
        }),
      )}
    </ol>
  );
};

export default CalendarWeekEventsView;
