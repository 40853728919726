import { Notification, NotificationType } from '@book-nestor-monorepo/shared-types';
import { faBellRing, faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import NotificationModalContent from './NotificationModal';

const NotificationIcon = ({
  messages: initialMessages,
  height = 48,
  width = 48,
}: {
  messages: Notification[];
  height?: number;
  width?: number;
}) => {
  const [messages, setMessages] = useState(initialMessages);
  const authContext = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleModalOpen = () => {
    openMuiModal(<NotificationModalContent messages={messages} onClose={closeMuiModal} />, 3);
  };

  useEffect(() => {
    if (
      authContext.user &&
      authContext.user.payment_connect_data?.connected_account_id &&
      !authContext.user.payment_connect_data?.payment_requirements_complete
    ) {
      const newMessage: Notification = {
        title: 'Stripe Setup Required',
        type: NotificationType.SYSTEM_ALERT,
        content: 'Please complete your Stripe setup to accept payments.',
        createdAt: Date.now(),
        link: authContext.user.payment_connect_data.complete_requirements_link,
      };
      const existingSystemAlert = messages.some(
        (message) => message.type === NotificationType.SYSTEM_ALERT,
      );
      if (!existingSystemAlert) {
        setMessages([...messages, newMessage]);
      }
    }
  }, []);

  return (
    <button
      type="button"
      className="-m-2.5 p-2.5 text-black hover:text-gray-500 cursor-pointer flex"
      onClick={() => handleModalOpen()}
    >
      <span className="sr-only">View notifications</span>
      {messages.length > 0 ? (
        <FontAwesomeIcon icon={faBellRing} className="size-5 lg:size-6 " />
      ) : (
        <FontAwesomeIcon icon={faBell} className="size-5 lg:size-6 " />
      )}
    </button>
    // <div className="relative">
    //   <button
    //     className={`bg-transparent rounded-full h-[${height}px] w-[${width}px] flex items-center justify-center relative`}
    //     onClick={() => handleModalOpen()}
    //   >
    //     <FontAwesomeIcon
    //       icon={faBellRing}
    //       className={`text-[18px] ${messages.length > 0 ? 'animate-pulse cursor-pointer text-black' : 'text-black'} `}
    //     />
    //     {/* {messages.length > 0 && (
    //       <span className="absolute top-0 right-0 bg-black-alt text-white text-[9px] font-semibold rounded-full w-4 h-4 flex items-center justify-center">
    //         {messages.length}
    //       </span>
    //     )} */}
    //   </button>
    // </div>
  );
};

export default NotificationIcon;
