import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { CreateCampaign, PaginatedCampaignItem } from '@book-nestor-monorepo/shared-types';

export const generateCampaignRecipients = async (userId: string, bookingId: string) => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/campaigns/recommendations/fill-slot/contacts`;

    const response = await axios.post(
      url,
      {
        booking_id: bookingId,
      },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data.recommendations || [];
  } catch (error) {
    throw new Error('Failed to generate campaign recipients');
  }
};

export const improveCampaignDescription = async (userId: string, description: string) => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/campaigns/recommendations/general/descriptions`;

    const response = await axios.post(
      url,
      {
        description: description,
      },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data.descriptions || [];
  } catch (error) {
    throw new Error('Failed to generate campaign description');
  }
};

export const generateCampaignDescription = async (userId: string, bookingId: string) => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/campaigns/recommendations/fill-slot/descriptions`;

    const response = await axios.post(
      url,
      {
        booking_id: bookingId,
      },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data.descriptions || [];
  } catch (error) {
    throw new Error('Failed to generate campaign description');
  }
};

export const createCampaign = async (campaign: CreateCampaign) => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/campaigns`;

    const response = await axios.post(url, campaign, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to create campaign');
  }
};

export const getCampaigns = async (): Promise<PaginatedCampaignItem> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/campaigns`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to get campaigns');
  }
};
