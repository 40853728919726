import { isGoogleImage } from '@book-nestor-monorepo/nestor-utils';
import { environment } from '../../../environments/environment';

interface ProfilePictureProps {
  picture?: string;
  firstName?: string;
  height?: number;
  width?: number;
  fontSize?: number;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  picture,
  firstName,
  height = 80,
  width = 80,
  fontSize = 38,
}) => {
  const styles = {
    height: `${height}px`,
    width: `${width}px`,
    fontSize: `${fontSize}px`,
  };

  if (picture && isGoogleImage(picture)) {
    return (
      <img
        src={picture}
        alt="profile"
        className="rounded-full"
        style={{ height: styles.height, width: styles.width }}
      />
    );
  } else if (picture) {
    return (
      <img
        src={`${environment.s3.bucketUrl}/${picture}`}
        alt="profile"
        className="rounded-full"
        style={{ height: styles.height, width: styles.width }}
      />
    );
  }
  return (
    <div
      className="bg-blue-status rounded-full flex items-center justify-center"
      style={{ height: styles.height, width: styles.width }}
    >
      <span className="text-white font-bold lg:pt-1" style={{ fontSize: styles.fontSize }}>
        {firstName?.[0]}
      </span>
    </div>
  );
};
