import { faCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';

interface SaveButtonProps {
  onClick: () => Promise<void>;
  disabled?: boolean;
  defaultText?: string;
  loadingText?: string | string[];
  loadingTextDuration?: number;
  successText?: string;
  width?: string;
  color?: string;
  bgColor?: string;
  shadow?: boolean;
}

export function SaveButton({
  onClick,
  disabled,
  defaultText = 'Update',
  loadingText = 'Update in Progress',
  loadingTextDuration = 2000,
  successText = 'Update',
  width = 'w-auto',
  color = 'auto',
  bgColor = 'auto',
  shadow = false,
}: SaveButtonProps) {
  const [buttonState, setButtonState] = useState<'default' | 'loading' | 'success'>('default');
  const [currentLoadingTextIndex, setCurrentLoadingTextIndex] = useState(0);

  const loadingTexts = Array.isArray(loadingText) ? loadingText : [loadingText];

  useEffect(() => {
    if (buttonState === 'loading' && loadingTexts.length > 1) {
      const interval = setInterval(() => {
        setCurrentLoadingTextIndex((prev) => (prev + 1) % loadingTexts.length);
      }, loadingTextDuration);
      return () => clearInterval(interval);
    }
  }, [buttonState, loadingTexts.length, loadingTextDuration]);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (buttonState === 'default' && !disabled) {
      setButtonState('loading');
      setCurrentLoadingTextIndex(0);
      try {
        await onClick();
        setButtonState('default');
      } catch (error) {
        setButtonState('default');
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex font-avenir items-center h-11 border text-[12px] text-${color} bg-${bgColor} rounded-full ${width} leading-4 justify-between ${
        disabled ? `opacity-90 cursor-not-allowed` : `bg-${bgColor}`
      } pl-4 ${buttonState === 'default' ? 'pl-6 pr-6' : 'pl-4'} ${shadow ? 'shadow-gray-500 shadow-lg' : ''}`}
      disabled={buttonState !== 'default' || disabled}
    >
      {buttonState === 'default' && defaultText}
      {buttonState === 'loading' && loadingTexts[currentLoadingTextIndex]}
      {buttonState === 'success' && defaultText}

      {buttonState !== 'default' && (
        <div
          className={`h-10 w-10 border bg-black-alt/10 flex items-center justify-center rounded-full mt-[1px] mb-[1px] mr-[1px] ml-[4px]`}
        >
          {buttonState === 'loading' && <FontAwesomeIcon icon={faSpinner} color={color} spin />}
          {buttonState === 'success' && <FontAwesomeIcon icon={faCheck} color={color} />}
        </div>
      )}
    </button>
  );
}
