import { Contact, ContactCommunication } from '@book-nestor-monorepo/shared-types';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { getContactComms } from '../../../libs/services/contacts';
import { AuthContext } from '../../../contexts/authContext';
import { convertToMomentTimeZone, formatHeaderDate } from '../../../libs/utils/date.util';
import LoadingDots from '../../../components/loading';

export const ContactCommsDrawer = ({ contact }: { contact: Contact }) => {
  const authContext = useContext(AuthContext);
  const [contactComms, setContactComms] = useState<ContactCommunication[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContactComms = async () => {
      if (!authContext.user || !authContext.user.id || !contact.id) return;
      setIsLoading(true);
      const contactComms = await getContactComms(authContext.user.id as string, contact.id);
      const sortedContactComms = contactComms.sort((a, b) => {
        return new Date(b.communication_date).getTime() - new Date(a.communication_date).getTime();
      });
      setContactComms(sortedContactComms);
      setIsLoading(false);
    };
    fetchContactComms();
  }, [contact]);

  return (
    <div className="flex flex-col w-full items-start justify-center">
      <div className="flex flex-col w-full items-start justify-center mb-4 border-b-2 border-gray-200 pb-4">
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <span className="text-[16px] font-medium text-left font-avenir">
            {contact.name} {contact.last_name}
          </span>
          <span className="text-[12px] text-left font-avenir text-gray-600">Contact</span>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 30,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <span className="text-[16px] font-medium text-left font-avenir">
            Recent Communication History
          </span>
          <div className="relative w-full mt-8">
            {/* Vertical line */}
            <div className="absolute left-[7px] top-2 bottom-2 w-[2px] bg-gray-200" />
            {contactComms.length === 0 && (
              <div className="space-y-6">
                <div className="flex items-start mb-8">
                  {/* Dot */}
                  <div className="relative">
                    <div className="w-4 h-4 rounded-full bg-black z-10 relative border-4 border-gray-200 "></div>
                  </div>
                  {/* Content */}
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900 font-avenir">
                      No communication history
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Timeline items */}
            {contactComms.length > 0 && (
              <div className="space-y-6">
                {contactComms.map((comms, index) => (
                  <div key={index} className="flex items-start mb-8">
                    {/* Dot */}
                    <div className="relative">
                      <div className="w-4 h-4 rounded-full bg-black z-10 relative border-4 border-gray-200 "></div>
                    </div>
                    {/* Content */}
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 font-avenir">
                        {comms.communication_channel} - {comms.communication_type}
                      </div>
                      <div className="text-[12px] text-gray-600 font-avenir">
                        {formatHeaderDate(
                          new Date(
                            convertToMomentTimeZone(
                              new Date(comms.communication_date),
                              authContext.user?.time_zone || 'America/Los_Angeles',
                            ),
                          ),
                        )}
                      </div>
                      <div className="text-[12px]  text-gray-600 font-inter mt-2 bg-gray-100 py-2 px-4 rounded-md">
                        {comms.communication_content}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
