import {
  Contact,
  ContactBooking,
  ContactCommunication,
  ContactNote,
  ContactPayment,
} from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import mime from 'mime';
import { environment } from '../../environments/environment';
import { uploadFile } from './organization.service';

async function fetchWithAuth(
  url: string,
  method: 'get' | 'put' | 'post' | 'delete',
  data?: any,
): Promise<any> {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const response = await axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return undefined; // means the contact does not exist
    }
    if (axios.isAxiosError(error) && error.response?.status === 409) {
      const err = new Error('A contact with this phone number already exists');
      (err as any).existingContactId = error.response.data.message.split('CONTACT_ID#')[1];
      throw err;
    }
    throw error;
  }
}

interface GetContactsOptions {
  filterFavorites?: boolean;
  filterDifficult?: boolean;
  filterRegular?: boolean;
  searchTerm?: string;
  email?: string;
  phoneNumber?: string;
}

export const getContacts = async (
  userId: string,
  options: GetContactsOptions,
): Promise<Contact[]> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const params = new URLSearchParams();
  if (options.filterFavorites === true) {
    params.append('isFavorite', options.filterFavorites.toString());
  }
  if (options.filterDifficult === true) {
    params.append('isDifficult', options.filterDifficult.toString());
  }
  if (options.filterRegular === true) {
    params.append('isRegular', options.filterRegular.toString());
  }
  if (options.searchTerm !== undefined) {
    params.append('searchTerm', encodeURIComponent(options.searchTerm));
  }
  if (options.email !== undefined) {
    params.append('email', options.email);
  }
  if (options.phoneNumber !== undefined) {
    params.append('phoneNumber', options.phoneNumber);
  }
  const url = `${apiBaseUrl}/user/${userId}/contacts?${params.toString()}`;
  const response = await fetchWithAuth(url, 'get');
  return response.items;
};

export const patchContactBooking = async (
  userId: string,
  contactId: string,
  bookingId: string,
  patchContactBooking: Partial<ContactBooking>,
): Promise<ContactBooking> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/bookings/${bookingId}`;

    const response = await axios.patch(url, patchContactBooking, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch contact bookings');
  }
};

export const getContactBooking = async (
  userId: string,
  contactId: string,
  bookingId: string,
): Promise<ContactBooking> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/bookings/${bookingId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch contact booking');
  }
};

export const getContact = async (userId: string, contactId: string): Promise<Contact> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}`;
  const response = await fetchWithAuth(url, 'get');
  return response;
};

export const getContactComms = async (
  userId: string,
  contactId: string,
): Promise<ContactCommunication[]> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/communications`;
  const response = await fetchWithAuth(url, 'get');
  return response.items || [];
};

export const createStripeCustomer = async (userId: string, contactId: string): Promise<Contact> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/paymentsAccount`;
  const response = await fetchWithAuth(url, 'post');
  return response;
};

export const getPaymentHistory = async (
  userId: string,
  contactId: string,
): Promise<ContactPayment[]> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/payments`;
  const response = await fetchWithAuth(url, 'get');
  return response.items || [];
};

export const createContact = async (userId: string, contact: Contact): Promise<Contact> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts`;
  const response = await fetchWithAuth(url, 'post', contact);
  return response;
};

export const updateContact = async (userId: string, contact: Contact): Promise<Contact> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contact.id}`;
  const response = await fetchWithAuth(url, 'put', contact);
  return response;
};

export const deleteContact = async (userId: string, contactId: string): Promise<undefined> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}`;
  const response = await fetchWithAuth(url, 'delete');
  return response;
};

export const getContactNotes = async (
  userId: string,
  contactId: string,
): Promise<ContactNote[]> => {
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/notes`;
  const response = await fetchWithAuth(url, 'get');
  return response.items;
};

export const createContactNote = async (
  userId: string,
  contactId: string,
  note: ContactNote,
  galleryFiles: File[],
): Promise<ContactNote> => {
  const session = await fetchAuthSession();
  const cognitoToken = session.tokens?.accessToken.toString() ?? '';

  const galleryFileUploadResponseCodes = [];
  const remoteGalleryFileNames = [];
  if (galleryFiles && galleryFiles.length > 0) {
    for (const file of galleryFiles) {
      if (!file.type) {
        const urlParts = file.name?.split('/');
        const cleanFileKey = urlParts?.[urlParts.length - 1];
        remoteGalleryFileNames.push(cleanFileKey);
      } else {
        const uploadGalleryName = `${uuidv4()}.${mime.getExtension(file.type)}`;
        const remoteGalleryFileName = `${userId}/business/gallery/${uploadGalleryName}`;
        const responseCode = await uploadFile(remoteGalleryFileName, file, cognitoToken);
        galleryFileUploadResponseCodes.push(responseCode);
        remoteGalleryFileNames.push(remoteGalleryFileName);
      }
    }
  }

  if (galleryFileUploadResponseCodes.length > 0) {
    note.gallery_image_keys = remoteGalleryFileNames;
  }

  const apiBaseUrl = environment.apiBaseUrl;
  let url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/notes`;
  if (note.id) {
    url = `${url}/${note.id}`;
    return await fetchWithAuth(url, 'put', note);
  }
  return await fetchWithAuth(url, 'post', note);
};
