import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, IconButton, Button } from '@mui/material';
import React from 'react';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { faCircleDot } from '@fortawesome/pro-solid-svg-icons';

interface DesktopNumberedServiceCardProps {
  number?: number;
  title: string;
  description?: string;
  buttonText: string;
  disabled?: boolean;
  onButtonClick?: () => void;
  canDismiss?: boolean;
  onDismiss?: () => void;
  onSelectTask?: (task: string) => void;
  confirmDeleteText?: string;
}

const DesktopNumberedServiceCard: React.FC<DesktopNumberedServiceCardProps> = ({
  number,
  title,
  description,
  buttonText,
  disabled,
  onButtonClick,
  canDismiss,
  onDismiss,
  onSelectTask,
  confirmDeleteText,
}) => {
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleConfirmDelete = () => {
    if (confirmDeleteText || onDismiss) {
      openMuiModal(
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Box sx={{ mb: 3 }}>
            {confirmDeleteText || 'Are you sure you want to skip this step?'}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                onDismiss?.();
                closeMuiModal();
              }}
              sx={{
                bgcolor: '#000000',
                '&:hover': { bgcolor: '#333333' },
                borderRadius: '24px',
                textTransform: 'none',
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => closeMuiModal()}
              sx={{
                color: '#000000',
                borderColor: '#000000',
                '&:hover': { borderColor: '#333333', color: '#333333' },
                borderRadius: '24px',
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>,
      );
    }
  };

  return (
    <Box display="flex" alignItems="center" mb={1} sx={{ textAlign: 'left' }}>
      <Card
        sx={{
          flexGrow: 1,
          opacity: disabled ? 0.5 : 1,
          transition: 'opacity 0.3s',
          position: 'relative',
          borderRadius: '24px',
          bgcolor: '#F7F7F7',
          marginBottom: '2px',
          border: 'none',
        }}
      >
        {canDismiss && (
          <IconButton
            size="small"
            onClick={handleConfirmDelete}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#000000',
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
        <CardContent sx={{ paddingY: 3, paddingX: 2 }}>
          <div className="flex w-full h-full items-center justify-start">
            <div className="flex-col w-auto h-full items-center justify-center mr-4">
              <div className="flex w-auto items-center justify-center">
                {!number && (
                  <div className="flex h-[48px] w-[48px] bg-[#000000] rounded-full items-center justify-center">
                    <FontAwesomeIcon icon={faCheck} color="#ffffff" className="text-[14px]" />
                  </div>
                )}
                {number && (
                  <div className="flex h-[48px] w-[48px] border-[2px] border-[#DADADA] rounded-full items-center justify-center">
                   <span className="text-[14px] font-avenir">{number}</span>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`flex flex-col items-start justify-center ${
                number ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={number ? () => onSelectTask?.(title) : undefined}
            >
              <div className="flex flex-row items-center justify-start mb-1">
                {number && <span className="text-[14px] font-avenir leading-4">Step {number}</span>}
              </div>
              <span className="text-[24px] font-inter font-semibold leading-6">{title}</span>
              {description && (
                <span className="text-[14px] text-black font-avenir leading-4 mt-4">
                  {description}
                </span>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DesktopNumberedServiceCard;
