import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTriangleExclamation, faX } from '@fortawesome/pro-light-svg-icons';

export const ContactConflictModal = ({
  onClose,
  title,
  message,
  existingContactId,
}: {
  onClose: () => void;
  title: string;
  message: string;
  existingContactId?: string;
}) => {
  return (
    <div className="h-full bg-[#F1F1F1] rounded-tl-3xl rounded-tr-3xl">
      <div className="flex p-2 flex-col">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 rounded-full p-2 text-gray-400 transition-colors hover:bg-gray-100 hover:text-gray-600"
        >
          <FontAwesomeIcon icon={faX} color="#000" size="xs" />
        </button>

        {/* Content */}
        <div className="mt-2">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-amber-500" size="sm" />
            <h2 className="text-lg font-semibold text-gray-900 ml-2">{title}</h2>
          </div>
          <p className="mt-4 text-sm text-gray-600">{message}</p>
          {existingContactId && (
            <a
              href={`/contacts/${existingContactId}`}
              className="mt-6 block text-sm font-medium textblack  hover:underline"
            >
              View existing contact <FontAwesomeIcon icon={faArrowRight} className="text-black" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
