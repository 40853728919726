import { TextMessage } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { environment } from '../../environments/environment';

export const createTextMessage = async (
  phoneNumber: string,
  recipientPhoneNumber: string,
  text: string,
): Promise<TextMessage | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/text/service-phone/${phoneNumber}/text-messages`,
      { to: recipientPhoneNumber, text },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};
