import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { validatePromoCode } from '../../../libs/services/subscription.service';
import { PromoCodeDto, SubscriptionProduct } from '@book-nestor-monorepo/shared-types';

const PromoCodeInput = ({
  onPromoCodeChange,
  selectedProduct,
}: {
  onPromoCodeChange: (code: string) => void;
  selectedProduct: SubscriptionProduct;
}): React.ReactElement => {
  const [promoCode, setPromoCode] = useState('');
  const [promoDetails, setPromoDetails] = useState<PromoCodeDto | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handlePromoCode = async (code: string) => {
    setIsLoading(true);
    setError('');

    try {
      const promoCodeDto = await validatePromoCode(code, selectedProduct?.id);

      if (promoCodeDto?.coupon.valid) {
        setPromoDetails(promoCodeDto);
        onPromoCodeChange(code);
      } else {
        setError('Invalid promo code');
        setPromoDetails(undefined);
        onPromoCodeChange('');
      }
    } catch (err) {
      setError('Error validating promo code');
      setPromoDetails(undefined);
      onPromoCodeChange('');
    } finally {
      setIsLoading(false);
    }
  };

  const generatePromoCodeDescription = (promoCodeDto: PromoCodeDto): string => {
    const { coupon } = promoCodeDto;

    // Handle invalid cases first
    if (!coupon?.valid) {
      return 'This promo code is not valid';
    }

    // Build discount description
    let discountText = '';
    if (coupon.percent_off) {
      discountText = `${coupon.percent_off}% off`;
    } else if (coupon.amount_off) {
      discountText = `$${(coupon.amount_off / 100).toFixed(2)} off`; // Assuming amount is in cents
    } else {
      return 'Invalid discount configuration';
    }

    // Build duration description
    let durationText = '';
    switch (coupon.duration) {
      case 'forever':
        durationText = 'for the lifetime of your subscription';
        break;
      case 'once':
        durationText = 'on your first payment';
        break;
      case 'repeating':
        if (coupon.duration_in_months === 1) {
          durationText = 'for 1 month';
        } else if (coupon?.duration_in_months && coupon.duration_in_months > 1) {
          durationText = `for ${coupon.duration_in_months} months`;
        } else {
          durationText = 'for a limited time'; // Fallback if duration_in_months is not set
        }
        break;
      default:
        durationText = 'on your purchase';
    }

    // Combine the parts into a natural-sounding description
    if (coupon.percent_off === 100) {
      if (coupon.duration === 'once') {
        return 'Your first month is free!';
      } else if (coupon.duration === 'forever') {
        return 'Free unlimited access!';
      } else if (coupon.duration === 'repeating' && coupon.duration_in_months) {
        return `Free for ${coupon.duration_in_months} ${coupon.duration_in_months === 1 ? 'month' : 'months'}!`;
      }
    }

    return `${discountText} ${durationText}`;
  };

  const clearPromoCode = () => {
    setPromoCode('');
    setPromoDetails(undefined);
    setError('');
    onPromoCodeChange('');
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex flex-row items-center relative">
        <input
          className="w-full pl-2 py-2 rounded-2xl border-1 border-gray-200 outline-1 outline-gray-200 focus:outline-1 focus:outline-gray-200 font-inter text-[14px] text-gray-700 placeholder:text-gray-400 placeholder:font-inter placeholder:text-[14px] uppercase"
          type="text"
          placeholder="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          disabled={promoDetails !== undefined}
        />
        {isLoading ? (
          <div className="absolute right-3">
            <FontAwesomeIcon icon={faSpinner} className="text-gray-400 animate-spin" />
          </div>
        ) : promoDetails ? (
          <button
            onClick={clearPromoCode}
            className="absolute right-3 text-gray-400 hover:text-gray-600"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        ) : (
          <button
            onClick={() => handlePromoCode(promoCode)}
            className="absolute right-3 px-4 py-1 text-sm font-inter text-blue-500 hover:text-blue-600"
            disabled={!promoCode}
          >
            Apply
          </button>
        )}
      </div>

      {error && <p className="text-red-500 ml-3 text-left font-inter text-[14px]">{error}</p>}

      {promoDetails && (
        <p className=" text-green-600 ml-3 text-left font-inter text-[14px]">
          {generatePromoCodeDescription(promoDetails)}
        </p>
      )}
    </div>
  );
};

export default PromoCodeInput;
