import {
  BookingConversationItem,
  BookingConversationMessageItem,
  PaginatedBookingConversationItem,
} from '@book-nestor-monorepo/shared-types';
import { AgentMessageCard } from './AgentMessageCard';
import { AgentMessageView } from '../AgentMenuDrawer/AgentMenuDrawerTabPanels/AgentMenuDrawerMessagesTabPanel';
import { formatDate } from '../../../libs/utils/date.util';
import { faAngleDown, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface AgentMessageCardListProps {
  agentMessages: PaginatedBookingConversationItem;
  onNavigate: (view: AgentMessageView) => void;
  agentConversationMessages: BookingConversationMessageItem[];
  fetchNextConversations: () => void;
  isLoading: boolean;
}

interface AgentMessageCardMapByDate {
  [date: string]: BookingConversationItem[];
}

export const AgentMessageCardList = ({
  agentMessages,
  onNavigate,
  agentConversationMessages,
  fetchNextConversations,
  isLoading,
}: AgentMessageCardListProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const agentMessageCardMapByDate = agentMessages.items.reduce<AgentMessageCardMapByDate>(
    (acc, agentMessage) => {
      const date = formatDate(new Date(agentMessage.created_at), '-');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(agentMessage);
      return acc;
    },
    {},
  );

  const handleFetchNextConversations = async () => {
    setIsLoadingMore(true);
    await fetchNextConversations();
    setIsLoadingMore(false);
  };

  return (
    <div className="flex flex-col  w-full">
      {Object.entries(agentMessageCardMapByDate).length === 0 && (
        <div className="block font-avenir text-[18px] text-[#FFFFFF] leading-5">No messages</div>
      )}

      {Object.entries(agentMessageCardMapByDate).map(([date, agentMessages]) => (
        <div className="flex flex-col items-start w-full" key={`${date}-${agentMessages.length}`}>
          <h3 className="text-[14px] font-avenir text-white mb-2">{date}</h3>
          {agentMessages.map((agentMessage) => (
            <div
              className="flex flex-col items-start w-full mb-2"
              key={`${agentMessage.id}-${agentMessage.updated_at}`}
            >
              <AgentMessageCard
                agentMessage={agentMessage}
                onNavigate={onNavigate}
                agentConversationMessage={agentConversationMessages.find(
                  (message) => message.conversation_id === agentMessage.id,
                )}
              />
            </div>
          ))}
        </div>
      ))}
      <div className="flex justify-center items-center w-full py-4 cursor-pointer">
        {agentMessages.meta.hasMore && !isLoadingMore && (
          <button
            onClick={handleFetchNextConversations}
            className="text-white text-[16px] font-avenir font-medium cursor-pointer"
          >
            Load more conversations
            <FontAwesomeIcon icon={faAngleDown} className="text-white pl-2 pt-1 text-[16px]" />
          </button>
        )}
        {isLoadingMore && <FontAwesomeIcon icon={faSpinner} className="text-white animate-spin" />}
      </div>
    </div>
  );
};
