import { faInfoCircle, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactRecommendation } from '@book-nestor-monorepo/shared-types';
import { AgentAiBorder } from '../../v2/Agent/AgentAiBorder';
const RecipientChips = ({
  recipients,
  onDelete,
}: {
  recipients: Partial<ContactRecommendation>[];
  onDelete: (index: number) => void;
}) => {
  const handleDelete = (indexToDelete: number) => {
    onDelete(indexToDelete);
  };

  return (
    <div className="flex flex-wrap gap-2 px-0 md:px-6">
      {recipients.map((recipient, index) => (
        <>
          {recipient.score ? (
            <AgentAiBorder key={index}>
              <div className="flex items-center bg-[#E5E8E8] rounded-full py-1 px-3 text-sm font-avenir">
                <span className="mr-2">
                  {recipient.contact?.name}
                  {recipient.contact?.last_name ? ` ${recipient.contact?.last_name}` : ''}
                </span>
                <button
                  onClick={() => handleDelete(index)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none text-[12px] font-avenir"
                  aria-label={`Remove ${recipient.contact?.name}`}
                >
                  <FontAwesomeIcon icon={faXmark} className="text-[12px] text-gray-600" />
                </button>
              </div>
            </AgentAiBorder>
          ) : (
            <div
              key={index}
              className="flex items-center bg-[#E5E8E8] rounded-full py-1 px-3 text-sm font-avenir"
            >
              <span className="mr-2">
                {recipient.contact?.name}
                {recipient.contact?.last_name ? ` ${recipient.contact?.last_name}` : ''}
              </span>
              <button
                onClick={() => handleDelete(index)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none text-[12px] font-avenir"
                aria-label={`Remove ${recipient.contact?.name}`}
              >
                <FontAwesomeIcon icon={faXmark} className="text-[12px] text-gray-600" />
              </button>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default RecipientChips;
