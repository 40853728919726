import {
  PaymentMethod,
  SubscriptionProduct,
  PromoCodeDto,
} from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export const getSubscriptionProducts = async (): Promise<SubscriptionProduct[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/subscriptions/products`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    const items = response.data.items as SubscriptionProduct[];

    items.sort((a, b) => (a.monthlyPrice?.price || 0) - (b.monthlyPrice?.price || 0));
    return items;
  } catch (error) {
    return [];
  }
};

export const createSubscription = async (
  paymentMethodId: string,
  priceId: string,
  promoCode?: string,
): Promise<void> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const payload = { paymentMethodId, priceId, promoCode };
    const response = await axios.post(`${apiBaseUrl}/subscriptions/subscriptions`, payload, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const changeSubscription = async (
  priceId: string,
  paymentMethodId?: string,
  promoCode?: string,
): Promise<any> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const payload = { paymentMethodId, priceId, promoCode };
    const response = await axios.put(`${apiBaseUrl}/subscriptions/subscriptions`, payload, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const createPortalSession = async (): Promise<any> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const response = await axios.post(
      `${apiBaseUrl}/subscriptions/portal-session`,
      {},
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};

export const getPaymentMethods = async (): Promise<PaymentMethod[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/subscriptions/payment-methods`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    const items = response.data.items as PaymentMethod[];

    return items;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const validatePromoCode = async (
  promoCode: string,
  productId: string,
): Promise<PromoCodeDto | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const response = await axios.get(
      `${apiBaseUrl}/subscriptions/promo-codes/${promoCode}/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};
