import { Contact } from '@book-nestor-monorepo/shared-types';
import { faX } from '@fortawesome/pro-light-svg-icons';

import { SearchAutocompleteContacts } from '../../components/v2/App/SearchAutoCompleteContacts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useState } from 'react';

export const CampaignBuilderContactModal = ({
  onClose,
  onSelectContact,
  onSelectAllContacts,
  includeAddAllContacts = false,
}: {
  onClose: () => void;
  onSelectContact: (contact: Contact | null) => void;
  onSelectAllContacts?: () => void;
  includeAddAllContacts?: boolean;
}) => {
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div>
          <span className="text-[16px] font-avenir font-medium">Add Campaign Recipient</span>
        </div>
        <button onClick={onClose} className="bg-black h-[40px] w-[40px] rounded-full p-2">
          <FontAwesomeIcon icon={faX} className="text-[14px] text-white" />
        </button>
      </div>
      <div className="flex items-center justify-between">
        <SearchAutocompleteContacts
          contacts={contacts}
          label="Select a Client"
          onSelectContact={(contact: Contact | null) => {
            setSelectedContact(contact);
          }}
        />
      </div>
      <div className="flex items-center justify-between mt-8">
        <button
          className="bg-black w-auto h-11 rounded-full px-4 py-2 text-white cursor-pointer text-[14px] font-avenir"
          disabled={!selectedContact}
          onClick={() => {
            onSelectContact(selectedContact);
            onClose();
          }}
        >
          Add to Campaign
        </button>
        {includeAddAllContacts && (
          <button
            className="bg-transparent w-auto h-11 rounded-full px-4 py-2 text-black cursor-pointer text-[14px] font-avenir border-2 border-black"
            onClick={() => {
              onSelectAllContacts?.();
              onClose();
            }}
          >
            Add All Contacts
          </button>
        )}
      </div>
    </div>
  );
};
