import { PaginatedCampaignItem, USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../../components/loading';
import { AuthContext } from '../../contexts/authContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { getCampaigns } from '../../libs/services/campaign';
import { hasAccess } from '../../libs/utils/hasAccess';
import { CampaignBrochure } from './campaign-brochure';
import { CampaignTable } from './campaigns.table';

export const CampaignsListV2 = () => {
  const { bgColor } = useAppExperience();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<PaginatedCampaignItem>({
    items: [],
    meta: {
      take: 0,
      hasMore: false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const user = useContext(AuthContext);
  const userCanUseCampaigns = hasAccess(user.token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      const campaigns = await getCampaigns();
      setCampaigns(campaigns);
      setIsLoading(false);
    }

    loadData();
  }, [userCanUseCampaigns]);

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        <div className="hidden lg:block justify-between items-center w-full pt-4 pb-4 lg:pb-8">
          <div className="flex items-center justify-between w-full px-1">
            <div className="flex items-center">
              <div className="">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="cursor-pointer text-black text-[16px] pb-[2px] pr-4 font-avenir font-normal pt-[2px]"
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingDots skeletonProps={{ count: 4 }} />
        ) : (
          <>
            {campaigns.items.length > 0 && userCanUseCampaigns ? (
              <CampaignTable campaigns={campaigns.items} campaignsMeta={campaigns.meta} />
            ) : (
              <CampaignBrochure />
            )}
          </>
        )}
      </div>
    </div>
  );
};
