import { Contact } from './contact';

export type CampaignRecommendationsFillSlotResponse = {
  recommendations: ContactRecommendation[];
};

export type CampaignRecommendationsFillSlotDescriptionResponse = {
  descriptions: DescriptionsRecommendation[];
};

export type DescriptionsRecommendation = {
  description: string;
  type: string;
};

export type ContactRecommendation = {
  contact: Partial<Contact>;
  score: number;
  last_booking_date: string;
  factors: Record<string, string>;
};

export type CampaignRecommendationsFillSlotRequest = {
  booking_id: string;
};

export type CampaignRecommendationsGeneralRequest = {
  description: string;
};

export enum CampaignType {
  FILL_SLOT = 'fill_slot',
  GENERAL = 'general',
}

export enum CampaignDeliveryMethod {
  SMS = 'SMS',
}

export enum CampaignDeliveryStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  FAILED = 'failed',
}

export type Campaign = {
  id: string;
  name: string;
  type: CampaignType;
  delivery_method: CampaignDeliveryMethod;
  recipients: Partial<ContactRecommendation>[];
  message: string;
  created_at: string;
  booking_id?: string;
  slot_filled: boolean;
  filled_slot_booking_id?: string;
  campaign_delivery_status: CampaignDeliveryStatus;
  campaign_metrics?: CampaignMetrics;
  include_all_contacts?: boolean;
};

export type CampaignResponse = {
  message: string;
  conversation_id: string;
};

export type CampaignMetrics = {
  sent_message_count: number;
  response_count: number;
  response_rate: number;
  failed_message_count: number;
  failed_message_reasons_by_contact_id: Record<string, string>;
  responses_by_contact_id: Record<string, CampaignResponse>;
};

export type CreateCampaign = {
  name: string;
  type: CampaignType;
  delivery_method: CampaignDeliveryMethod;
  recipients: Partial<ContactRecommendation>[];
  message: string;
  booking_id?: string;
  include_all_contacts?: boolean;
};

interface PaginationMeta {
  take: number;
  hasMore: boolean;
  nextCursor?: string;
}

export interface PaginatedCampaignItem {
  items: Campaign[];
  meta: PaginationMeta;
}
