import { UserSlug } from './user-slug';

export enum SubscriptionPlan {
  // FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  STUDIO = 'studio',
  INACTIVE = 'inactive',
}

export interface UserPermissions {
  permissions: string[];
}

export enum USER_PERMISSIONS {
  CAN_USE_VOICE = 'canUseVoice', // AI phone operator & Phone number, Smart Voicemail
  CAN_USE_TEXT = 'canUseText', // 2 way text messaging
  CAN_USE_BUSINESS_BRANDING = 'canUseBusinessBranding', // Beautiful booking site
  CAN_USE_BUSINESS_REVIEWS = 'canUseBusinessReviews', // Reviews
  CAN_USE_BUSINESS_DESCRIPTION = 'canUseBusinessDescription',
  CAN_USE_CUSTOM_SLUG = 'canUseCustomSlug',
  CAN_USE_AI_ASSISTANT = 'canUseAIAssistant',
}

export const USER_PERMISSIONS_BASIC = [];
export const USER_PERMISSIONS_PRO = [
  ...USER_PERMISSIONS_BASIC, // everything in BASIC
  USER_PERMISSIONS.CAN_USE_BUSINESS_BRANDING,
  USER_PERMISSIONS.CAN_USE_BUSINESS_REVIEWS,
  USER_PERMISSIONS.CAN_USE_BUSINESS_DESCRIPTION,
  USER_PERMISSIONS.CAN_USE_CUSTOM_SLUG,
];
export const USER_PERMISSIONS_STUDIO = [
  ...USER_PERMISSIONS_PRO, // everything in PRO
  USER_PERMISSIONS.CAN_USE_VOICE,
  USER_PERMISSIONS.CAN_USE_TEXT,
  USER_PERMISSIONS.CAN_USE_AI_ASSISTANT,
];
export const USER_PERMISSIONS_INACTIVE = [];

export const USER_PERMISSIONS_MAP: Record<SubscriptionPlan, UserPermissions> = {
  [SubscriptionPlan.BASIC]: {
    permissions: USER_PERMISSIONS_BASIC,
  },
  [SubscriptionPlan.PRO]: {
    permissions: USER_PERMISSIONS_PRO,
  },
  [SubscriptionPlan.STUDIO]: {
    permissions: USER_PERMISSIONS_STUDIO,
  },
  [SubscriptionPlan.INACTIVE]: {
    permissions: USER_PERMISSIONS_INACTIVE,
  },
};

export const getUserPermissions = (subscription: SubscriptionPlan): string[] => {
  return USER_PERMISSIONS_MAP[subscription]?.permissions || [];
};

export interface ExternalPaymentCustomer {
  id: string;
}

export interface ExternalSubscriptionItem {
  id: string;
  metadata?: Record<string, string>;
  product?: any;
  plan: {
    id: string;
    active: boolean;
    amount: number;
    currency: string;
    interval: string;
  };
  price: {
    id: string;
    amount: number;
    lookup_key: string;
  };
}

export interface ExternalSubscription {
  id: string;
  items: ExternalSubscriptionItem[];
}

export interface User {
  id?: string;
  first_name?: string;
  email?: string;
  phone_number?: string;
  picture?: string;
  created_at?: string;
  updated_at?: string;
  activation_data?: ActivationData; // required steps to enter the app
  connection_data?: ConnectionData;
  user_slug?: UserSlug;
  subscription?: SubscriptionPlan;
  external_payment_customer?: ExternalPaymentCustomer;
  external_subscription?: ExternalSubscription;
  payment_connect_data?: PaymentConnectData;
  time_zone?: string;
  auth_user_name?: string;
  onboarding_data?: OnboardingData;
  industry?: string;
  other_industry?: string;
  website?: string;
}

export interface PaymentConnectData {
  provider: string;
  connected_account_id: string;
  payment_requirements_complete?: boolean;
  complete_requirements_link?: string;
}

export interface ActivationData {
  getting_started_tasks?: GettingStartedTask[];
  has_seen_getting_started: boolean;
}

export interface OnboardingData {
  onboarding_tasks?: OnboardingTask[];
}

export interface OnboardingTask {
  name: OnboardingTaskNames;
  completed_at?: string;
  exclusion_product_tiers: SubscriptionPlan[];
  can_dismiss: boolean;
}

export interface GettingStartedTask {
  name: TaskNames;
  completed_at?: string;
}

export interface ConnectionData {
  grant_id?: string;
  access_token?: string;
  refresh_token?: string;
  sub?: string;
  email?: string;
  code?: string;
  cal_provider_user_id?: number;
  cal_provider_user_name?: string;
}

export interface PaymentConnectData {
  provider: string;
  connected_account_id: string;
}

export enum OnboardingTaskNames {
  createAccount = 'createAccount',
  verifyYourTimeZone = 'verifyYourTimeZone',
  updateBusinessInfo = 'updateBusinessInfo',
  addFirstBusinessImage = 'addFirstBusinessImage',
  createAService = 'createAService',
  verifyAvailability = 'verifyAvailability',
  verifyAIAssistant = 'verifyAIAssistant',
  linkStripeAccount = 'linkStripeAccount',
  updateBookingLink = 'updateBookingLink',
}

export enum TaskNames {
  welcome = 'welcome',
  onboardingOutro = 'onboardingOutro',
}
