import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScaledBooker } from '../../components/v2/Booker/ScaledBooker';
import { SearchAutocompleteServices } from '../../components/v2/App/SearchAutoCompleteServices';
import { Skeleton } from '@mui/material';
import { SearchAutocompleteContacts } from '../../components/v2/App/SearchAutoCompleteContacts';
import { Contact } from '@book-nestor-monorepo/shared-types';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useServices } from '../../contexts/v2/eventTypesContext';
import { EventType } from '@book-nestor-monorepo/shared-types';
import { AuthContext } from '../../contexts/authContext';
import { useToast } from '../../contexts/v2/toastContext';
import { bookingWebhook } from '../../libs/services/bookings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { ContactDetailCard } from '../../components/v2/Contact/ContactDetailCard';
import { useMuiModal } from '../../contexts/muiFlyupModal';
export const BookerMobilePage = () => {
  const [bookerKey, setBookerKey] = useState(0);
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [selectedService, setSelectedService] = useState<EventType | null>();
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const latestContactRef = useRef<string | null>(null);
  const [latestCreatedContactId, setLatestCreatedContactId] = useState<string | null>(null);
  const location = useLocation();
  const contactId = location.state?.contactId;

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
  }, [services, contacts]);

  useEffect(() => {
    setBookerKey((prevKey) => prevKey + 1);
  }, [selectedContact, selectedService]);

  useEffect(() => {
    if (contactId) {
      setSelectedContact(contacts.find((contact) => contact.id === contactId) || null);
    }
  }, [contactId, contacts]);

  const handleBookingSuccess = async (data: any) => {
    showToast('Booking created successfully!', 'success');
    await bookingWebhook({
      booking_id: data?.uid as string,
      user_id: user?.id as string,
      rescheduleUid: null,
      is_cancelled: false,
      contact_id: selectedContact?.id as string,
    });
    navigate('/');
  };

  const openContactModal = () => {
    openMuiModal(
      <ContactDetailCard
        onSuccess={async (contactId?: string) => {
          if (contactId) {
            latestContactRef.current = contactId; // Set the ref before fetching
            setLatestCreatedContactId(contactId);
          }
          await fetchContacts(true);
          closeMuiModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const handleNewContact = async () => {
      if (contacts.length > 0 && latestCreatedContactId) {
        const newContact = contacts.find((contact) => contact.id === latestCreatedContactId);
        if (newContact && latestContactRef.current === latestCreatedContactId) {
          setSelectedContact(newContact);
          // Clear refs after successful selection
          latestContactRef.current = null;
          setLatestCreatedContactId(null);
        }
      }
    };

    handleNewContact();
  }, [contacts, latestCreatedContactId]);

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        {/* Container 2 */}
        <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
          <div className="flex w-full items-start justify-between">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Clients</p>
            </div>
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={openContactModal}
            >
              <div className="w-[20px] h-[20px]  flex items-center justify-center">
                <FontAwesomeIcon icon={faPlus} className="text-black text-[16px]" />
              </div>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingContacts ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteContacts
                contacts={contacts}
                label="Book a Client"
                onSelectContact={(contact: Contact | null) => {
                  setSelectedContact(contact);
                }}
                selectedContact={selectedContact}
              />
            )}
          </div>
        </div>

        {/* Container 3 */}
        <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
          <div className="flex w-full items-start justify-start">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Services</p>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingServices ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteServices
                services={services}
                label="Book a Service"
                onSelectService={(service: EventType | null) => {
                  setSelectedService(service);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-4">
        {services.length > 0 && selectedService && selectedContact && (
          <ScaledBooker
            key={bookerKey}
            scale={1}
            eventTypeSlug={selectedService?.slug as string}
            username={user?.connection_data?.cal_provider_user_name as string}
            contact={selectedContact as Contact}
            onSuccess={handleBookingSuccess}
          />
        )}
      </div>
    </>
  );
};
