// DesktopMenuDrawer.tsx
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { useState } from 'react';

import {
  faArrowUpLeftFromCircle,
  faBuildingUser,
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faClock,
  faDollarSign,
  faEnvelope,
  faGears,
  faList,
  faQuestionCircle,
  faRefresh,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useMuiModal } from '../../contexts/muiFlyupModal';

export interface MenuItem {
  icon?: () => React.ReactNode;
  label: string;
  action?: () => void | string; // Can be a function or a URL string
}

interface DesktopMenuDrawerProps {
  open: boolean;
  onToggle: () => void;
  anchor: 'left' | 'right';
  menuItems: MenuItem[];
  behindOverlay?: boolean;
}

const DesktopMenuDrawer: React.FC<DesktopMenuDrawerProps> = ({
  open,
  onToggle,
  anchor,
  menuItems,
  behindOverlay = false,
}) => {
  const zIndex = behindOverlay ? 'z-0' : 'z-50';
  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const DesktopMenuItem: React.FC<{
    item: MenuItem;
    onToggle: () => void;
    iconsOnly?: boolean;
  }> = ({ item, onToggle, iconsOnly = false }) => {
    return (
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            if (typeof item.action === 'function') {
              item.action();
              if (open) {
                onToggle();
                setShowSettings(false);
              }
            } else if (typeof item.action === 'string') {
              window.location.href = item.action;
            } else if (item.label === 'Settings') {
              setShowSettings(!showSettings);
              if (!open) {
                onToggle();
                setShowSettings(false);
              }
            } else {
              onToggle();
              setShowSettings(false);
            }
          }}
          className="justify-center !px-1.5"
        >
          <Tooltip title={item.label} placement="right" arrow>
            <ListItemIcon>
              <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                {item.icon && <span className="text-base text-black">{item.icon()}</span>}
              </div>
            </ListItemIcon>
          </Tooltip>
          {!iconsOnly && (
            <span className="ml-5 font-avenir text-[14px] leading-6">{item.label}</span>
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  const DrawerList = (
    <Box role="presentation">
      <List>
        {menuItems.map((item, index) => (
          <div key={item.label + index}>
            {item.label === 'Divider' ? (
              <div className="flex pt-12 pl-2">
                <span className="text-[14px] font-avenir font-bold">MyNestor</span>
              </div>
            ) : (
              <DesktopMenuItem item={item} onToggle={onToggle} />
            )}
          </div>
        ))}
      </List>
    </Box>
  );

  const DrawerListIcons = (
    <Box role="presentation">
      <List>
        {menuItems.map((item, index) => (
          <div key={item.label + index}>
            {item.label === 'Divider' ? (
              <div className="my-5 pl-4 mr-6">
                <Divider />
              </div>
            ) : (
              <DesktopMenuItem item={item} onToggle={onToggle} iconsOnly={true} />
            )}
          </div>
        ))}
      </List>
    </Box>
  );

  const handleSettingsMenuClick = (path: string) => {
    setShowSettings(!showSettings);
    onToggle();
    if (path) {
      navigate(path);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const renderOpenSettingsMenu = () => {
    return showSettings ? (
      <div className="flex flex-col justify-start items-start w-full h-full">
        <div className="flex pt-6 pl-2">
          <span className="text-[14px] font-avenir font-bold">Client Engagement</span>
        </div>
        <div className="flex flex-col gap-2 pl-2 pt-2">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/communication')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faEnvelope} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Communication</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/business-profile')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faBuildingUser} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Business Profile</span>
          </div>
        </div>
        <div className="flex pt-12 pl-2">
          <span className="text-[14px] font-avenir font-bold">Booking Management</span>
        </div>
        <div className="flex flex-col gap-2 pl-2 pt-2">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/services')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faList} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Services & Pricing</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/linked-calendars')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faCalendar} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Linked Calendars</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/availability')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faClock} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Availability</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/payments')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faDollarSign} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Payments</span>
          </div>
        </div>
        <div className="flex pt-12 pl-2">
          <span className="text-[14px] font-avenir font-bold">Nestor</span>
        </div>
        <div className="flex flex-col gap-2 pl-2 pt-2">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/personal-settings')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faGears} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Personal Settings</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/subscription')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faRefresh} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Subscription</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => window.Intercom?.('show')}
          >
            <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faQuestionCircle} className="text-[14px]" />
            </div>
            <span className="text-[14px] font-avenir pl-6">Help & Support</span>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start items-start w-full h-full">{DrawerList}</div>
    );
  };

  return (
    <>
      {/* Overlay */}
      {open && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 z-40 transition-opacity duration-300 ease-in-out"
          onClick={onToggle}
        ></div>
      )}

      {/* Drawer */}
      <div id={`drawer-container-desktop-${anchor}`}>
        <div
          className={`absolute top-0 ${anchor}-0 h-full ${zIndex} transition-[width] duration-300 ease-in-out ${
            open ? 'w-[280px]' : 'w-[58px]'
          } ${open ? 'bg-[#E5E8E8] rounded-[20px]' : 'bg-[#E5E8E8] rounded-[50px]'}`}
        >
          {/* Toggle button */}
          <div
            className={`w-full flex  items-center pt-1 ${open ? 'justify-start pl-1' : 'justify-center'}`}
          >
            <button
              onClick={onToggle}
              className="w-12 h-12 rounded-full bg-white flex items-center justify-center"
            >
              <FontAwesomeIcon
                className="text-[14px]"
                icon={open ? faChevronLeft : faChevronRight}
              />
            </button>
          </div>
          {open && (
            <div>
              <div className="flex items-start justify-between px-4 py-4">
                <div className="flex">
                  <span className="text-[32px] font-avenir">
                    {showSettings ? 'Settings' : 'Menu'}
                  </span>
                </div>
              </div>
              {showSettings && (
                <div
                  className="flex items-center  justify-start cursor-pointer pl-4"
                  onClick={() => setShowSettings(false)}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-[12px] pb-[4px]"
                    color="#13ACD4"
                  />
                  <span className="text-[16px] font-avenir pl-2 text-[#13ACD4]">
                    Back to main menu
                  </span>
                </div>
              )}
            </div>
          )}

          {/* Menu items */}
          {open ? (
            renderOpenSettingsMenu()
          ) : (
            <div className="flex justify-start items-start w-full h-full pt-32">
              {DrawerListIcons}
            </div>
          )}

          {/* Bottom section */}
          <div
            className={`absolute bottom-0 w-full flex  ${
              open ? 'justify-start pl-1' : 'justify-center'
            }`}
          >
            <Tooltip title="Logout" placement="right" arrow>
              <div
                className="w-12 h-12 bg-[#B2B4B4] rounded-full flex items-center justify-center mb-[5px] cursor-pointer"
                onClick={handleLogout}
              >
                <FontAwesomeIcon
                  icon={faArrowUpLeftFromCircle}
                  className="text-white text-[14px]"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopMenuDrawer;
