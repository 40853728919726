import React, { createContext, useContext, useReducer } from 'react';
import { Contact } from '@book-nestor-monorepo/shared-types';

interface InitialState {
  contacts: Contact[];
}

const initialState: InitialState = {
  contacts: [],
};

export const ActionTypes = {
  SET_CONTACTS: 'SET_CONTACTS',
  ADD_CONTACT: 'ADD_CONTACT',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
};

const reducer = (state: InitialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.SET_CONTACTS:
      return { ...state, contacts: action.payload };
    case ActionTypes.ADD_CONTACT:
      return { ...state, contacts: [...state.contacts, action.payload] };
    case ActionTypes.UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact,
        ),
      };
    case ActionTypes.DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== action.payload.id),
      };
    default:
      return state;
  }
};

interface StoreContextType {
  state: InitialState;
  dispatch: React.Dispatch<{ type: string; payload: any }>;
}

export const StoreContext = createContext<StoreContextType>({} as StoreContextType);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};
