import React from 'react';
interface AgentAiBorderProps {
  children: React.ReactNode;
  styles?: AgentBorderStyles;
}

interface AgentBorderStyles {
  rounded?: 'full' | 'md';
  disabled?: boolean;
}

export const AgentAiBorder = ({ children, styles }: AgentAiBorderProps) => {
  const rounded = styles?.rounded || 'full';
  const disabled = styles?.disabled || false;

  const roundedClass = rounded === 'full' ? 'rounded-full' : 'rounded-md';

  return (
    <div
      className={`relative p-[2px] ${roundedClass} bg-gradient-to-r from-pink-500 via-purple-500 to-cyan-500 animate-gradient-x ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <div className={`relative bg-transparent ${roundedClass}`}>{children}</div>
    </div>
  );
};
