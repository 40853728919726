import { DescriptionsRecommendation } from '@book-nestor-monorepo/shared-types';
import { useState } from 'react';

const EditableMessageChips = ({
  messages,
  onDelete,
  onSelect,
}: {
  messages: DescriptionsRecommendation[];
  onDelete: (index: number) => void;
  onSelect: (message: DescriptionsRecommendation) => void;
}) => {
  const [selectedMessage, setSelectedMessage] = useState<DescriptionsRecommendation | null>(null);

  const handleSelect = (message: DescriptionsRecommendation) => {
    setSelectedMessage(message);
    onSelect(message);
  };

  return (
    <div className="flex flex-col space-y-6">
      {/* Message Chips */}
      <div className="flex flex-wrap gap-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex items-start justify-between  rounded-lg py-2 px-4 cursor-pointer transition-colors duration-200 ${
              message === selectedMessage
                ? 'bg-[#43CA5120] border border-[#43CA5150]'
                : 'bg-[#E5E8E8]'
            }`}
          >
            <div
              className="flex-1 mr-3 text-sm text-left font-avenir max-w-[300px]"
              onClick={() => handleSelect(message)}
            >
              <div className="text-subtle text-[10px] font-avenir pt-1 pb-3">{message.type}</div>
              {message.description}
            </div>
          </div>
        ))}
      </div>

      {/* Edit Area */}
      {/* {selectedMessage && ( */}
      {/* <div className="flex flex-col space-y-3">
        <textarea
          value={editingText}
          onChange={handleTextChange}
          className="w-full p-3 border border-gray-300 rounded-md font-avenir text-sm min-h-[100px]  outline-none"
          placeholder="Edit your campaign message..."
        />
      </div> */}
      {/* )} */}
    </div>
  );
};

export default EditableMessageChips;
