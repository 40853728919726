import React from 'react';
import { CalendarWeekView } from './calendar-week-view';
import CalendarMonthView from './calendar-month-view';
import { CalendarDay, CalendarError } from './calendar-container';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CalendarViewProps {
  currentView: 'day' | 'week' | 'month';
  isLoading: boolean;
  currentDateRange: { start: string; end: string };
  calendarDays: CalendarDay[];
  handleSetCurrentView: (view: 'day' | 'week' | 'month') => void;
  handleContactCommsClick: (bookingId: string) => void;
  handleBackwardWeek: () => void;
  handleForwardWeek: () => void;
  handleBackwardMonth: () => void;
  handleForwardMonth: () => void;
  handleDayClick: (day: CalendarDay) => void;
  selectedDay: CalendarDay | null;
  handleTodayClick: () => void;
  error: CalendarError | null;
  handleAddEventClick: () => void;
}

const DayView: React.FC = () => (
  <div className="h-full">
    <div>Day View Coming Soon</div>
  </div>
);

const WeekView: React.FC<CalendarViewProps> = ({
  handleSetCurrentView,
  handleContactCommsClick,
  currentDateRange,
  calendarDays,
  handleBackwardWeek,
  handleForwardWeek,
  handleDayClick,
  selectedDay,
  currentView,
  handleTodayClick,
  error,
  handleAddEventClick,
}) => (
  <div className="w-full h-full flex flex-col overflow-hidden">
    <CalendarWeekView
      setCurrentView={handleSetCurrentView}
      onEventClick={handleContactCommsClick}
      currentDateRange={currentDateRange}
      calendarDays={calendarDays}
      handleBackwardWeek={handleBackwardWeek}
      handleForwardWeek={handleForwardWeek}
      handleDayClick={handleDayClick}
      selectedDay={selectedDay}
      currentView={currentView}
      handleTodayClick={handleTodayClick}
      error={error}
      handleAddEventClick={handleAddEventClick}
    />
  </div>
);

const MonthView: React.FC<CalendarViewProps> = ({
  handleSetCurrentView,
  handleContactCommsClick,
  currentDateRange,
  calendarDays,
  handleBackwardMonth,
  handleForwardMonth,
  handleDayClick,
  selectedDay,
  currentView,
  handleTodayClick,
  error,
  handleAddEventClick,
}) => (
  <div className="h-auto w-full">
    <CalendarMonthView
      setCurrentView={handleSetCurrentView}
      onEventClick={handleContactCommsClick}
      currentDateRange={currentDateRange}
      calendarDays={calendarDays}
      handleBackwardMonth={handleBackwardMonth}
      handleForwardMonth={handleForwardMonth}
      handleDayClick={handleDayClick}
      selectedDay={selectedDay}
      currentView={currentView}
      handleTodayClick={handleTodayClick}
      error={error}
      handleAddEventClick={handleAddEventClick}
    />
  </div>
);

const ViewComponent: React.FC<CalendarViewProps> = ({ currentView, isLoading, ...props }) => {
  switch (currentView) {
    case 'day':
      return <DayView />;
    case 'week':
      return <WeekView currentView={currentView} isLoading={isLoading} {...props} />;
    case 'month':
    default:
      return <MonthView currentView={currentView} isLoading={isLoading} {...props} />;
  }
};

const CalendarView: React.FC<CalendarViewProps> = ({ isLoading, currentView, ...props }) => {
  return (
    <div className="flex flex-col h-full w-full mt-4 px-1 pb-4">
      <div className="flex-1 overflow-hidden relative h-full">
        <ViewComponent currentView={currentView} {...props} isLoading={isLoading} />
        {isLoading && (
          <div className="absolute inset-0 bg-gray-50/80 flex items-center justify-center rounded-2xl">
            <FontAwesomeIcon icon={faSpinner} className="text-black animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarView;
