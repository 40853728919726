import Intercom from '@intercom/messenger-js-sdk';
import { CalProvider } from '@calcom/atoms';
import { Amplify } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthIsNotSignedIn, AuthIsSignedIn } from './contexts/authContext';
import { WebSocketProvider } from './contexts/websocket.context';
import { environment as environmentConfig } from './environments/environment';
import { MainRoute, SignInRoute } from './routes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { StoreProvider } from './contexts/storeContext';
import { QuickStartProvider } from './contexts/quickStartContext';
import { AppExperienceProvider } from './contexts/v2/appExperienceContext';

Amplify.configure({
  Auth: {
    Cognito: {
      allowGuestAccess: false,
      // region: environmentConfig.awsRegion,
      userPoolId: environmentConfig.userPoolId,
      identityPoolId: environmentConfig.identityPoolId,
      userPoolClientId: environmentConfig.userPoolClientId,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: environmentConfig.oauthDomainName,
          scopes: ['phone', 'email', 'profile', 'aws.cognito.signin.user.admin', 'openid'],
          responseType: 'code',
          redirectSignIn: [environmentConfig.cognito.oauth.redirectSignIn],
          redirectSignOut: [environmentConfig.cognito.oauth.redirectSignOut],
        },
      },
    },
  },
});

function App() {
  const authContext = useContext(AuthContext);

  const setViewHeight = () => {
    let windowWidth = 0;

    if (
      window.innerHeight < window.innerWidth &&
      320 < window.innerWidth &&
      1024 > window.innerWidth
    ) {
      windowWidth = window.innerWidth;
    } else {
      windowWidth = window.innerHeight;
    }

    const vh = windowWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    // Call setViewHeight on mount and on resize
    setViewHeight();
    window.addEventListener('resize', setViewHeight);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', setViewHeight);
    };
  }, []);

  useEffect(() => {
    if (authContext.user && window.Intercom) {
      window.Intercom('boot', {
        app_id: 'c1b7psk1',
        user_id: authContext.user.id,
        name: authContext.user.first_name,
        email: authContext.user.email,
        created_at: authContext?.user?.created_at
          ? new Date(authContext?.user?.created_at).getTime()
          : undefined,
        hide_default_launcher: true,
      });

      return () => {
        if (window.Intercom) {
          window.Intercom('shutdown');
        }
      };
    }
  }, [authContext.user]);

  return (
    <StoreProvider>
      <div className="safe-top safe-bottom h-screen antialiased scroll-smooth text-optimize-legibility m-0 p-0 relative overflow-x-hidden font-inter bg-[#e5e6e1]">
        <CalProvider
          accessToken={authContext.user?.connection_data?.access_token}
          clientId={environmentConfig.calcom.clientId ?? ''}
          options={{
            apiUrl: environmentConfig.calcom.apiUrl ?? '',
            refreshUrl: `${environmentConfig.calcom.refreshUrl}?userId=${authContext.user?.id}`,
          }}
        >
          {/* <WebSocketProvider> */}
          <AuthIsSignedIn>
            <QuickStartProvider>
              <AppExperienceProvider>
                <MainRoute
                  token={authContext.token}
                  subscription={authContext.user?.subscription}
                />
              </AppExperienceProvider>
            </QuickStartProvider>
          </AuthIsSignedIn>
          <AuthIsNotSignedIn>
            <GoogleReCaptchaProvider
              scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
              }}
              reCaptchaKey={environmentConfig.recaptcha.siteKey}
            >
              <SignInRoute />
            </GoogleReCaptchaProvider>
          </AuthIsNotSignedIn>
          {/* </WebSocketProvider> */}
        </CalProvider>
      </div>
    </StoreProvider>
  );
}

export default App;
