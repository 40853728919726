import React, { memo, useMemo, useCallback } from 'react';
import { Contact, ContactBookingMethod } from '@book-nestor-monorepo/shared-types';
import { Booker } from '@calcom/atoms';

interface ScaledBookerProps {
  scale: number;
  eventTypeSlug: string;
  username: string;
  contact: Contact;
  rescheduleUid?: string;
  onSuccess: (data: any) => void;
}

export const ScaledBooker = memo(({ scale = 0.8, onSuccess, ...props }: ScaledBookerProps) => {
  // Memoize form values to prevent them from being recreated each render
  const defaultFormValues = useMemo(
    () => ({
      firstName: props.contact?.name || ' ',
      lastName: props.contact?.last_name || ' ',
      name: props.contact ? `${props.contact?.name} ${props.contact?.last_name}` : ' ',
      email: props.contact?.email || 'no-reply@mynestor.com',
    }),
    [props.contact],
  );

  // Memoize metadata object
  const metadata = useMemo(
    () => ({
      method: ContactBookingMethod.MANUAL_USER,
    }),
    [],
  );

  // Memoize success callback
  const handleSuccess = useCallback(
    async (data: any) => {
      if (onSuccess) {
        onSuccess(data.data);
      }
    },
    [onSuccess],
  );

  return (
    <div
      className="scaled-booker-wrapper"
      style={{
        fontSize: `${scale}rem`,
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }}
    >
      <Booker
        key={`${props.eventTypeSlug}-${props.username}-${props.contact?.id}`}
        rescheduleUid={props.rescheduleUid}
        eventSlug={props.eventTypeSlug}
        username={props.username}
        defaultFormValues={defaultFormValues}
        metadata={metadata}
        onCreateBookingSuccess={handleSuccess}
      />
    </div>
  );
});

ScaledBooker.displayName = 'ScaledBooker';
