export const truncateText = (text: string, maxLength: number): string => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength - 3) + '...';
  }
};

export const cleanCalcomEventTitleToJustEventType = (title: string): string => {
  if (!title) {
    return '';
  }
  const index = title.indexOf(' between');
  return index !== -1 ? title.substring(0, index) : title;
};
