import { Box, CircularProgress } from '@mui/material';
import React from 'react';

interface CircularProgressProps {
  value: number;
  total: number;
  size?: string;
  thickness?: number;
  color?: string;
  hideRemainingItems?: boolean;
}

const CircularProgressWithLabel: React.FC<CircularProgressProps> = ({
  value,
  total,
  size = '48px',
  thickness = 6,
  color = '#4caf50', // default to a green color
  hideRemainingItems = false,
}) => {
  const remainingItems = Math.max(0, total - value);
  const progressPercentage = (value / total) * 100;
  const numericSize = parseInt(size);

  return (
    <Box position="relative" display="inline-flex" width={size} height={size}>
      <svg
        width={size}
        height={size}
        style={{ position: 'absolute' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={numericSize / 2}
          cy={numericSize / 2}
          r={numericSize / 2 - 0.5} // Slightly reduce radius to ensure full circle fits
          fill="white"
          shapeRendering="geometricPrecision" // This improves anti-aliasing
        />
      </svg>
      <CircularProgress
        variant="determinate"
        value={progressPercentage}
        size={size}
        thickness={thickness}
        style={{
          color: color,
          position: 'absolute',
          left: 0,
        }}
      />
      {!hideRemainingItems && (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <span className="text-[16px] font-bold font-avenir text-black">{remainingItems}</span>
        </Box>
      )}
    </Box>
  );
};

interface StackedCircularProgressProps {
  size?: string;
  thickness?: number;
  completedValue: number;
  completedTotal: number;
  completedColor?: string;
  totalValue: number;
  totalTotal: number;
  totalColor?: string;
}

const StackedCircularProgress: React.FC<StackedCircularProgressProps> = ({
  size = '48px',
  thickness = 3,
  completedValue,
  completedTotal,
  completedColor = '#000000',
  totalValue,
  totalTotal,
  totalColor = '#DDDDDD',
}) => {
  return (
    <Box position="relative" width={size} height={size}>
      <Box position="absolute" top={0} left={0}>
        <CircularProgressWithLabel
          value={totalValue}
          total={totalTotal}
          size={size}
          thickness={thickness}
          color={totalColor}
          hideRemainingItems
        />
      </Box>
      <Box position="absolute" top={0} left={0}>
        <CircularProgressWithLabel
          value={completedValue}
          total={completedTotal}
          size={size}
          thickness={thickness}
          color={completedColor}
        />
      </Box>
    </Box>
  );
};

export { StackedCircularProgress };
