import { Organization } from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import mime from 'mime';
import { resizeImage } from '../utils/resizeImage.util';
import * as Sentry from '@sentry/react';

export const upsertOrganization = async (
  org: Organization,
  logoFile?: File,
  galleryFiles?: any, // File[] | string[],
) => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString() ?? '';
  const apiBaseUrl = environment.apiBaseUrl;
  const userId = session?.tokens?.accessToken?.payload?.sub;

  const uploadPromises: Promise<string | number | undefined>[] = [];

  let remoteLogoFileName: string | undefined;
  if (logoFile) {
    const uploadLogoName = `${uuidv4()}.${mime.getExtension(logoFile.type)}`;
    remoteLogoFileName = `${userId}/business/${uploadLogoName}`;
    const smallerImage = await resizeImage(logoFile);
    uploadPromises.push(uploadFile(remoteLogoFileName, smallerImage, cognitoToken));
  }

  const remoteGalleryFileNames: string[] = [];
  if (galleryFiles && galleryFiles.length > 0) {
    const uploadGalleryPromises = galleryFiles.map(async (file: any) => {
      if (!file.type) {
        const cleanFileKey = file.includes('.com')
          ? file.split('.com/')[1]
          : file.split('.net/')[1];
        remoteGalleryFileNames.push(cleanFileKey);
      } else {
        const uploadGalleryName = `${uuidv4()}.${mime.getExtension(file.type)}`;
        const remoteGalleryFileName = `${userId}/business/gallery/${uploadGalleryName}`;
        const smallerImage = await resizeImage(file);
        await uploadFile(remoteGalleryFileName, smallerImage, cognitoToken);
        remoteGalleryFileNames.push(remoteGalleryFileName);
      }
    });

    uploadPromises.push(...uploadGalleryPromises);
  }

  try {
    await Promise.all(uploadPromises);

    const payload: Organization = {
      ...org,
    };

    if (remoteLogoFileName) {
      payload.logo_image_key = remoteLogoFileName;
    }

    if (remoteGalleryFileNames.length > 0) {
      payload.gallery_image_keys = remoteGalleryFileNames;
    } else {
      payload.gallery_image_keys = [];
    }

    let response;
    if (org.id) {
      response = await axios.put(`${apiBaseUrl}/organizations`, payload, {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      });
    } else {
      response = await axios.post(`${apiBaseUrl}/organizations`, payload, {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      });
    }
    return response.data;
  } catch (error) {
    throw new Error('Error creating organization');
  }
};

export const uploadFile = async (remoteFilename: string, file: File, token: string) => {
  const response = await axios.post(
    `${environment.apiBaseUrl}/file/upload`,
    { file_name: remoteFilename, file_type: file.type },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const upload_url = response.data.upload_url;
  if (upload_url) {
    try {
      const uploadRes = await axios.put(upload_url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      return uploadRes.status;
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};

export const getOrganization = async (userId?: string): Promise<Organization | undefined> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    let url = `${apiBaseUrl}/organizations`;
    if (userId) {
      url = `${url}?userId=${userId}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching organization');
  }
};
