import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';
import {
  AccountLinkRequest,
  AccountSession,
  Checkout,
  ExchangeToken,
  OauthUser,
  PaymentAccount,
  User,
} from '@book-nestor-monorepo/shared-types';

export const validateCaptcha = async (token: string): Promise<{ success: boolean }> => {
  const apiBaseUrl = environment.apiBaseUrl;
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/verify-recaptcha`, { token });
    return response.data;
  } catch (error) {
    throw new Error('Failed to validate recaptcha');
  }
};

export const exchangeCodeForToken = async (code: string): Promise<string> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const userId = session?.tokens?.accessToken?.payload?.sub || '';

    const requestBody: ExchangeToken = {
      user_id: userId,
      code,
      redirect_uri: environment.oauth.redirectUri,
    };

    const response = await axios.post(`${apiBaseUrl}/auth/exchange`, requestBody, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.url;
  } catch (error) {
    throw new Error('Failed to exchange code');
  }
};

export const createAccountSession = async (connectedAccountId: string): Promise<AccountSession> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const requestBody: AccountSession = {
      connected_account_id: connectedAccountId,
    };

    const response = await axios.post(`${apiBaseUrl}/auth/payments/session`, requestBody, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to create account session');
  }
};

export const createCheckoutSession = async (createCheckout: Checkout): Promise<{ url: string }> => {
  const session = await fetchAuthSession();
  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;

  const createCheckoutRequest: Checkout = {
    connected_account_id: createCheckout.connected_account_id,
    amount: createCheckout.amount,
    product_name: createCheckout.product_name,
    customer_email: createCheckout.customer_email,
    customer_id: createCheckout.customer_id,
    ...(createCheckout.booking_id && { booking_id: createCheckout.booking_id }),
  };

  try {
    const redirectLinkResponse = await axios.post(
      `${apiBaseUrl}/auth/payments/checkout`,
      createCheckoutRequest,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return redirectLinkResponse.data;
  } catch (error) {
    throw new Error('Failed to create checkout session');
  }
};

export const linkStripeAccount = async (
  createPaymentAccount: PaymentAccount,
  refresh_url?: string,
  return_url?: string,
): Promise<{ url: string }> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const response: { data: { connected_account_id: string } } = await axios.post(
      `${apiBaseUrl}/auth/payments/connect`,
      createPaymentAccount,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    const accountLinkRequest: AccountLinkRequest = {
      connected_account_id: response.data.connected_account_id,
      return_url: return_url || environment.stripe.paymentsLinkRedirect,
      refresh_url: refresh_url || environment.stripe.paymentsLinkRedirect,
    };

    const redirectLinkResponse = await axios.post(
      `${apiBaseUrl}/auth/payments/accountLink`,
      accountLinkRequest,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return redirectLinkResponse.data;
  } catch (error) {
    throw new Error('Failed to create account session');
  }
};
