import { BookingProgressDots } from './booking-progress-dots';

export const BookingContainer = () => {
  return (
    <div className="flex flex-col w-full items-start justify-center bg-white p-2">
      <div className="flex  w-full items-center justify-center ">
        <BookingProgressDots />
      </div>
    </div>
  );
};
