import { Contact, EventType } from '@book-nestor-monorepo/shared-types';
import { BookingResponse } from '@calcom/atoms/dist/packages/features/bookings/types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchAutocompleteContacts } from '../../components/v2/App/SearchAutoCompleteContacts';
import { SearchAutocompleteServices } from '../../components/v2/App/SearchAutoCompleteServices';
import { ScaledBooker } from '../../components/v2/Booker/ScaledBooker';
import { ContactDetailCard } from '../../components/v2/Contact/ContactDetailCard';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useServices } from '../../contexts/v2/eventTypesContext';
import { useToast } from '../../contexts/v2/toastContext';
import { bookingWebhook } from '../../libs/services/bookings';

export default function HomeBookings() {
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { user } = useContext(AuthContext);
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const { showToast } = useToast();
  const [selectedService, setSelectedService] = useState<EventType | null>();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [latestCreatedContactId, setLatestCreatedContactId] = useState<string | null>(null);
  const latestContactRef = useRef<string | null>(null);

  const [searchParams] = useSearchParams();
  const [rescheduleUid, setRescheduleUid] = useState<string | undefined>(undefined);

  useEffect(() => {
    const rescheduleUid = searchParams.get('rescheduleUid') ?? undefined;
    setRescheduleUid(rescheduleUid);
  }, [searchParams]);

  const updateUrlWithoutNavigation = (date: string, url: string) => {
    const newUrl = `${url}?date=${date}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const handleBookingSuccess = async (data: BookingResponse) => {
    showToast('Booking created successfully!', 'success');

    const currentUrl = new URL(window.location.href);
    const dateParam = currentUrl.searchParams.get('date');
    if (dateParam) {
      // Update the URL without navigating
      updateUrlWithoutNavigation(dateParam, currentUrl.pathname);
    }
    if (user?.user_slug?.slug && data?.uid) {
      window.scrollTo(0, 0);
    }
    await bookingWebhook({
      booking_id: data?.uid as string,
      user_id: user?.id as string,
      rescheduleUid: null,
      is_cancelled: false,
      contact_id: selectedContact?.id as string,
    });
    setSelectedContact(null);
    setSelectedService(services[0]);
  };

  const shouldRenderBooker = true;

  function scaledBookerOrSplash() {
    if (services.length > 0 && selectedService && shouldRenderBooker) {
      return (
        <ScaledBooker
          key={stableKey}
          rescheduleUid={rescheduleUid}
          scale={1} // Adjust this value as needed
          eventTypeSlug={selectedService?.slug as string}
          username={user?.connection_data?.cal_provider_user_name as string}
          contact={selectedContact as Contact}
          onSuccess={handleBookingSuccess}
        />
      );
    } else {
      return (
        <div className="flex-grow flex items-center justify-center p-4 h-full">
          <div className="flex flex-col items-center justify-center">
            <p className="font-avenir font-bold text-[43px] leading-[50px] ">
              Create your first service to start booking!
            </p>
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    if (services.length > 0 && !selectedService) {
      setSelectedService(services[0]);
    }
  }, [services]);

  const stableKey = useMemo(() => {
    return `${selectedContact?.id || ''}-${selectedService?.id || ''}`;
  }, [selectedContact?.id, selectedService?.id]);

  const openContactModal = () => {
    openMuiModal(
      <ContactDetailCard
        onSuccess={async (contactId?: string) => {
          if (contactId) {
            latestContactRef.current = contactId; // Set the ref before fetching
            setLatestCreatedContactId(contactId);
          }
          await fetchContacts(true);
          closeMuiModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const handleNewContact = async () => {
      if (contacts.length > 0 && latestCreatedContactId) {
        const newContact = contacts.find((contact) => contact.id === latestCreatedContactId);
        if (newContact && latestContactRef.current === latestCreatedContactId) {
          setSelectedContact(newContact);
          // Clear refs after successful selection
          latestContactRef.current = null;
          setLatestCreatedContactId(null);
        }
      }
    };

    handleNewContact();
  }, [contacts, latestCreatedContactId]);

  return (
    <>
      <div className="hidden lg:flex flex-col h-full w-full">
        <div className="flex space-x-4 mb-2 flex-grow min-h-[140px] max-h-[140px]">
          <div className="bg-[#E5E8E8] rounded-xl shadow-sm flex-1 p-4">
            <div className="flex w-full items-start justify-between">
              <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                <p className="text-white text-[10px] font-avenir">Clients</p>
              </div>
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={openContactModal}
              >
                <div className="w-[20px] h-[20px]  flex items-center justify-center">
                  <FontAwesomeIcon icon={faPlus} className="text-black text-[16px]" />
                </div>
              </div>
            </div>
            <div className="flex w-full  items-start justify-start pt-4">
              {isLoadingContacts ? (
                <Skeleton
                  variant="rounded"
                  width={'100%'}
                  height={30}
                  sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                />
              ) : (
                <SearchAutocompleteContacts
                  contacts={contacts}
                  label="Book a Client"
                  onSelectContact={(contact: Contact | null) => {
                    setSelectedContact(contact);
                  }}
                  selectedContact={selectedContact}
                />
              )}
            </div>
          </div>

          <div className="bg-[#E5E8E8] rounded-xl shadow-sm flex-1 p-4">
            <div className="flex w-full items-start justify-start">
              <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                <p className="text-white text-[10px] font-avenir">Services</p>
              </div>
            </div>
            <div className="flex w-full  items-start justify-start pt-4">
              {isLoadingServices ? (
                <Skeleton
                  variant="rounded"
                  width={'100%'}
                  height={30}
                  sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                />
              ) : (
                <SearchAutocompleteServices
                  services={services}
                  label="Book a Service"
                  onSelectService={(service: EventType | null) => {
                    setSelectedService(service);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          id="in-app-booker"
          className="bg-white rounded-xl shadow-sm flex w-full overflow-hidden justify-center items-center"
        >
          <div className="flex w-full h-full justify-center items-center">
            {isLoadingServices || isLoadingContacts ? (
              <div className="p-4">
                <MultiPartSkeleton color="#DADCDC" />
              </div>
            ) : (
              scaledBookerOrSplash()
            )}
          </div>
        </div>
      </div>
      <div className=" flex flex-col h-auto w-full lg:hidden  mt-4">
        <div className="flex flex-col w-full gap-4">
          <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
            <div className="flex w-full items-start justify-between">
              <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                <p className="text-white text-[10px] font-avenir">Clients</p>
              </div>
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={openContactModal}
              >
                <div className="w-[20px] h-[20px]  flex items-center justify-center">
                  <FontAwesomeIcon icon={faPlus} className="text-black text-[16px]" />
                </div>
              </div>
            </div>
            <div className="flex w-full  items-start justify-start pt-4">
              {isLoadingContacts ? (
                <Skeleton
                  variant="rounded"
                  width={'100%'}
                  height={30}
                  sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                />
              ) : (
                <SearchAutocompleteContacts
                  contacts={contacts}
                  label="Book a Client"
                  onSelectContact={(contact: Contact | null) => {
                    setSelectedContact(contact);
                  }}
                  selectedContact={selectedContact}
                />
              )}
            </div>
          </div>

          <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
            <div className="flex w-full items-start justify-start">
              <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                <p className="text-white text-[10px] font-avenir">Services</p>
              </div>
            </div>
            <div className="flex w-full  items-start justify-start pt-4">
              {isLoadingServices ? (
                <Skeleton
                  variant="rounded"
                  width={'100%'}
                  height={30}
                  sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                />
              ) : (
                <SearchAutocompleteServices
                  services={services}
                  label="Book a Service"
                  onSelectService={(service: EventType | null) => {
                    setSelectedService(service);
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col pt-4 h-full w-full  overflow-scroll  hide-scrollbar">
          {services.length > 0 && selectedService && selectedContact && shouldRenderBooker && (
            <ScaledBooker
              key={stableKey}
              scale={1}
              eventTypeSlug={selectedService?.slug as string}
              username={user?.connection_data?.cal_provider_user_name as string}
              contact={selectedContact as Contact}
              onSuccess={handleBookingSuccess}
            />
          )}
        </div>
      </div>
    </>
  );
}
