import { EventType, Organization, Review, Schedule } from '@book-nestor-monorepo/shared-types';
import { FC } from 'react';
import BookingService from './BookingService';
import DetailsTab from './Tabs/DetailsTab';
import HoursTab from './Tabs/HoursTab';
import ReviewsTab from './Tabs/ReviewsTab';
import { environment } from '../../environments/environment';

type TabType = {
  label: string;
  active: boolean;
};

interface IProps {
  tabs: TabType[];
  selectTab: (idx: number) => void;
  eventTypes: EventType[];
  organization: Organization;
  defaultSchedule: Schedule;
  reviews: Review[];
  contactId?: string;
}

interface GroupedEventTypes {
  [categoryName: string]: EventType[];
}

const BookingTabs: FC<IProps> = ({
  tabs,
  selectTab,
  eventTypes,
  organization,
  defaultSchedule,
  reviews,
  contactId,
}) => {
  const activeTab = tabs.find((tab) => tab.active);
  const eventTypesToDisplay =
    eventTypes.length > 0
      ? eventTypes
      : [
          {
            title: 'Service Name',
            price: 0,
            duration_mins: 0,
            description: 'Service Description',
            categories: [],
            minimum_booking_notice_mins: 10,
            slot_interval: 0,
          },
        ];
  const groupedEventTypes = groupEventTypesByCategory(eventTypesToDisplay);

  function groupEventTypesByCategory(eventTypes: EventType[]): GroupedEventTypes {
    const groupedEventTypes: GroupedEventTypes = {};

    eventTypes.forEach((eventType) => {
      // Add the event type to the "All Services" category
      if (!groupedEventTypes['All Services']) {
        groupedEventTypes['All Services'] = [];
      }
      groupedEventTypes['All Services'].push(eventType);

      // Group event types by their specific categories
      (eventType.categories || []).forEach((category) => {
        if (!groupedEventTypes[category.name]) {
          groupedEventTypes[category.name] = [];
        }
        groupedEventTypes[category.name].push(eventType);
      });
    });

    return groupedEventTypes;
  }

  const reviewImage = organization.gallery_image_keys?.[0]
    ? `${environment.s3.bucketUrl}/${organization.gallery_image_keys?.[0]}`
    : '/assets/bookings/booking-main-coming-soon-desktop.jpg';

  return (
    <div className="w-full bg-[#D7D8D3] font-avenir hidden md:block">
      <div className="md:w-[750px] lg:w-[1000px] mx-auto py-[24px]">
        <div className="w-full h-[64px] rounded-full bg-[#E5E6E1]">
          <div className="w-[639px] h-full max-w-full mx-auto flex justify-between items-end">
            {tabs.map(({ label, active }, idx) => (
              <button
                key={label}
                onClick={() => selectTab(idx)}
                className={`text-[#00000080] h-[41px] flex flex-col gap-[9px] items-center ${active && 'text-black-alt font-bold'}`}
              >
                {label}
                {active && <div className="w-[32px] h-2 bg-black-alt" />}
              </button>
            ))}
          </div>
        </div>
        {activeTab?.label === 'Services' ? (
          <div>
            {Object.entries(groupedEventTypes).map(([categoryName, eventTypes]) => (
              <div key={categoryName}>
                <BookingService
                  key={categoryName}
                  label={categoryName}
                  eventTypes={eventTypes}
                  contactId={contactId}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex bg-[#E5E6E1] rounded-[24px] overflow-hidden mt-6">
            {activeTab?.label === 'Reviews' && (
              <div
                className={`flex items-center justify-center bg-cover w-[388px] min-w-[388px] h-[432px]`}
                style={{ backgroundImage: `url(${reviewImage})` }}
              >
                {/* {activeTab?.label !== 'Reviews' && (
                <button className="w-[184px] py-3 bg-black-alt rounded-full text-white">
                  Directions
                </button>
              )} */}
              </div>
            )}
            <div className="w-full overflow-hidden flex items-center justify-center w-full">
              {activeTab?.label === 'Details' && <DetailsTab organization={organization} />}
              {activeTab?.label === 'Hours' && (
                <div className="w-full flex items-center justify-center">
                  <HoursTab defaultSchedule={defaultSchedule} />
                </div>
              )}
              {activeTab?.label === 'Reviews' && <ReviewsTab reviews={reviews} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingTabs;
