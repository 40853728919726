import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import CalendarContainer from '../../components/calendar/calendar-container';
export const SchedulePageV2 = () => {
  const { bgColor } = useAppExperience();

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        <CalendarContainer />
      </div>
    </div>
  );
};
